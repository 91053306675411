import { Panel, useReactFlow, useViewport } from '@xyflow/react';
import { fitIcon, reloadIcon, zoomInIcon, zoomOutIcon } from 'containers/App/constants';
import SVG from 'react-inlinesvg';
import { ControlsContainer } from './styled';

const CustomControl = (props = {}) => {
  const { replanHandler = null } = props;
  const { setViewport, fitView } = useReactFlow();
  const { x, y, zoom } = useViewport();
  const zoomIn = () => setViewport({ x: x, y: y, zoom: zoom < 2 ? zoom + 0.25 : zoom });
  const zoomOut = () => setViewport({ x: x, y: y, zoom: zoom > 1.25 ? zoom - 0.25 : zoom });

  return (
    <Panel position="bottom-center">
      <ControlsContainer>
        <SVG src={zoomOutIcon} className="ms-1" width={24} height={24} onClick={zoomOut} />
        <SVG src={zoomInIcon} className="ms-1" width={24} height={24} onClick={zoomIn} />
        <SVG src={fitIcon} className="ms-1" width={24} height={24} onClick={fitView} />
        <div className="vertical-divider" />
        <SVG src={reloadIcon} width={24} height={24} onClick={replanHandler} />
      </ControlsContainer>
    </Panel>
  );
};
export default CustomControl;
