import styled from 'styled-components';
import { primaryNew, primaryDarkNew, successNew, white } from 'themes/variables';
import ReactModal from 'react-modal';

export const PasswordFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const ToolTipUlWrapper = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 0px;
    text-align: left;
    width: 100%;
    li {
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
export const UserTypeButtonGroup = styled.div`
  background-color: white;
  border-radius: 23px;
  border: 1px solid rgba(${primaryNew}, 0.2);
  display: inline-flex;
  padding: 2px;
  margin-bottom: 50px;
  height: 46px;

  button {
    font-size: 14px;
    line-height: 16px;
    color: rgba(${primaryDarkNew}, 0.7);
    border-radius: 23px;
    border: 0;
    height: 40px;
    padding: 8px 20px;
    background: transparent;

    &.active {
      color: rgb(${white});
      background: rgb(${primaryNew});
    }
  }
`;

export const UserBulletPointList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 9px;
    display: grid;
    grid-template-columns: auto 1fr;

    &:last-child {
      margin-bottom: 0;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    p {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &.border-rounded {
    border-radius: 15px !important;
  }
`;

export const ContactModal = styled(ReactModal)`
  .pipedriveWebForms {
    overflow-y: scroll;
  }
  .pricing-compare-div {
    height: 800px;
    overflow: auto;
    .pricing-column-heading {
      display: flex;
      overflow-y: scroll;
      justify-content: space-between;
      border-bottom: 1px dashed #04004e1a;
      padding: 20px 0;
      > div {
        flex-basis: 20%; /* Default: 20% for all */

        &:first-child {
          flex-basis: 40%; /* First div gets 40% */
        }
        :not(:first-child) {
          text-align: center;
        }
      }
      .pricing-table-main-heading {
        text-align: center;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 600;
        line-height: 33px;
      }
      @media (max-width: 768px) {
        .pricing-table-main-heading {
          font-size: 18px;
        }
      }
      .hide-price {
        display: none;
      }
      .border {
        border: unset !important;
      }
      .pink-sub-heading {
        color: #d573ff;
        text-align: left;
        margin: 30px 0 0;
        padding-right: 0;
        font-family: New order, sans-serif;
        font-weight: 700;
        line-height: 33px;
      }
    }
    .pricing-row {
      display: flex;
      border-bottom: 1px dashed #04004e1a;
      padding: 20px 0;
      .pink-sub-heading {
        color: #d573ff;
        text-align: left;
        margin: 30px 0 0;
        padding-right: 0;
        font-family: New order, sans-serif;
        font-weight: 700;
        line-height: 33px;
      }
      .border {
        border: unset !important;
      }
      > div {
        flex-basis: 20%; /* Default: 20% for all */

        &:first-child {
          flex-basis: 40%; /* First div gets 40% */
        }
        :not(:first-child) {
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .pricing-compare-div {
      height: 80vh;
    }
  }
`;
