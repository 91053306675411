/* eslint-disable react/no-unused-state */
/**
 * SubscriptionModal
 * This is the onboarding page for the client user, at the '/about-you' route
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import StorageService from 'utils/StorageService';
import get from 'lodash/get';
import find from 'lodash/find';
import { signupLink } from 'containers/App/utils';
import request from 'utils/request';
import { P, H2, Button, A, SVGIcon, H6, H3, H5, H1 } from 'components';
import { CardSkeleton } from 'components/SkeletonLoader';
import ModalWrapper from 'components/ModalWrapper';
import {
  currencyData,
  BUILD_TEAM_FORM_URL,
  PRICING_URL,
  rightAngleIcon,
  API_URL,
  CLIENT,
  LIST_PRICES,
  SUBSCRIPTION,
  SUBSCRIBE,
  arrowRightIcon,
  LIST_PLANS,
  CONTACT_US_FORM_URL,
  errorIcon,
} from 'containers/App/constants';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import { VALIDATION } from 'utils/constants';
import ToastifyMessage from 'components/ToastifyMessage';
import { primaryNew } from 'themes/variables';
import messages from './messages';
import { PlanCard } from './styles';
import { subscriptionPlans } from './utils';
import { ContactModal } from 'containers/Auth/SignUp/signup-styles';

export class SubscriptionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionPlanModal: false,
      subscriptionPrices: [],
      subscriptionLoading: false,
      planData: [],
      isPlanDataLoading: false,
      oneTimeAmount: 0,
      showModal: null,
    };
    this.subscriptionPlanModal = this.subscriptionPlanModal.bind(this);
  }

  /**
   * call on open handleSubscriptionPlanOpenModal popup
   * @author Innovify
   */
  handleSubscriptionPlanOpenModal = () => {
    this.setState({ showSubscriptionPlanModal: true });
  };

  /** call on open handleSubscriptionPlanCloseModal popup
   * @author Innovify
   */
  handleSubscriptionPlanCloseModal = () => {
    this.setState({ showSubscriptionPlanModal: false });
  };

  handleSubscribePlan = (priceId, planType) => {
    const { history } = this.props;
    if (planType === 0) {
      this.setState({ subscriptionLoading: true });
    } else {
      this.setState({ subscriptionTwoLoading: true });
    }
    const currentHost = window.location.host;
    const protocolType = window.location.protocol;
    const successURL = `${protocolType}//${currentHost}${history?.location?.pathname}?success=true`;
    const cancelURL = `${protocolType}//${currentHost}${history?.location?.pathname}?success=false`;

    const body = { priceId, successURL, cancelURL };
    const data = { method: 'POST', body };
    const requestURL = `${API_URL}${CLIENT}${SUBSCRIPTION}${SUBSCRIBE}`;

    request(requestURL, data)
      .then((response) => {
        if (get(response, 'status')) {
          if (planType === 0) {
            this.setState({ subscriptionLoading: false });
          } else {
            this.setState({ subscriptionTwoLoading: false });
          }
          window.location.replace(response?.data);
        } else {
          if (planType === 0) {
            this.setState({ subscriptionLoading: false });
          } else {
            this.setState({ subscriptionTwoLoading: false });
          }
          toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
        }
      })
      .catch(() => {
        if (planType === 0) {
          this.setState({ subscriptionLoading: false });
        } else {
          this.setState({ subscriptionTwoLoading: false });
        }
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  render() {
    const { invalid, loading, responseSuccess, responseError } = this.props;
    return <>{this.subscriptionPlanModal(loading, responseSuccess, responseError, invalid)}</>;
  }

  subscriptionPlanModal(loading, responseSuccess, responseError, invalid) {
    const { isNormalUpgrade, isTalentUpgrade } = this.props;
    const { showSubscriptionPlanModal, subscriptionPrices, isPlanDataLoading, oneTimeAmount, showModal } = this.state;
    // For single job-post calculation
    const oneTimeCurrency = subscriptionPrices.length > 0 && subscriptionPrices.find((a) => a.type === 'one_time')?.currency;
    const oneTimeCurrencySign = getCurrencySymbol(currencyData, 'code', oneTimeCurrency?.toString().toUpperCase());

    const payNowAction = () => {
      StorageService.set('ONE_TIME_SUBSCRIPTION_PRICE', oneTimeAmount);
      this.handleSubscriptionPlanCloseModal();
      // eslint-disable-next-line no-shadow
      const { history } = this.props;
      const successURL = `${history?.location?.pathname}?success=true`;
      const cancelURL = `${history?.location?.pathname}?success=false`;
      history.push('/client/checkout', { successURL, cancelURL });
    };

    return (
      <ModalWrapper
        modalId="showSubscriptionPlanModal"
        loading={loading}
        responseSuccess={responseSuccess}
        responseError={responseError}
        disabled={invalid}
        // isOpen
        isOpen={showSubscriptionPlanModal}
        onDiscard={this.handleSubscriptionPlanCloseModal}
        primaryBtnText="Ok"
        title=""
        onHandleSubmit={this.handleSubscriptionPlanCloseModal}
        modalBodyClass="no-header-footer p-0"
        modalHeaderClass="d-none"
        modalContentClass={this.props.modalContentClass}
        modalClassName={this.props.modalClassName}
        noFooter
      >
        <H1 className="text-center mb-3">
          <FormattedMessage {...(isTalentUpgrade || isNormalUpgrade ? messages.normalUpgradeTitle : messages.forceUpgradeTitle)} />
        </H1>
        <div className="d-flex justify-content-center w-100">
          <P
            className="p16 text-center mb-3 mb-xxl-4 "
            style={{
              width: '80%',
            }}
          >
            {isTalentUpgrade ? (
              `Access global talent database, attract the best talents, host more competitions, and build multiple scrum teams anywhere with a click of a button.`
            ) : isNormalUpgrade ? (
              <FormattedMessage {...messages.normalUpgradeDescription} />
            ) : (
              <FormattedMessage {...messages.forceUpgradeDescription} />
            )}
          </P>
        </div>
        {!isNormalUpgrade && !isTalentUpgrade && (
          <>
            <hr className="mt-3 mb-3" />
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
              <H6>
                <FormattedMessage {...messages.additionalJobText} />
              </H6>
              <div className="d-flex align-items-center mt-3 mt-md-0">
                <div className="d-flex align-items-center">
                  <H3 className="text-primary">
                    {oneTimeCurrencySign}
                    {oneTimeAmount}
                  </H3>
                  <P className="p16 mb-0">/per job</P>
                </div>
                <Button type="button" className="ms-3 btn btn-outline btn-sm mt-auto" onClick={payNowAction}>
                  <FormattedMessage {...messages.payNowBtn} />
                  <SVGIcon src={arrowRightIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                </Button>
              </div>
            </div>
            <hr className="mt-3 mb-4" />
          </>
        )}
        {!isNormalUpgrade && !isTalentUpgrade && (
          <div className="my-1 py-2">
            <>
              <H6 className="me-2">
                <FormattedMessage {...messages.upgradToSuitablePlan} />
              </H6>
            </>
          </div>
        )}
        {isPlanDataLoading ? (
          <div className="d-flex flex-column flex-md-row">
            {isNormalUpgrade ? (
              <>
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />{' '}
              </>
            ) : (
              <>
                <CardSkeleton cardCount={1} />
                <CardSkeleton cardCount={1} />
              </>
            )}
          </div>
        ) : (
          <Row className="mt-4 d-flex align-items-stretch">
            {subscriptionPlans.map((subscriptionPlan) => (
              <Col md={'4'} key={subscriptionPlan?.title}>
                <PlanCard className="d-flex flex-column ">
                  <div className="inner-block">
                    <H2 className={`fw-600 ${subscriptionPlan.headerClass || ''}`}>{subscriptionPlan.title}</H2>
                    <P className={`mb-0 ${subscriptionPlan.descriptionClass || ''}`} opacityVal="0.6">
                      {subscriptionPlan.description}
                    </P>
                    <div>
                      <Button
                        type="button"
                        className="btn btn-sm btn-outline mt-3 contact-us-btn"
                        onClick={() =>
                          this.setState(
                            {
                              showModal: 'contact-form',
                            },
                            () => {
                              setTimeout(() => {
                                const s = document.createElement('script');
                                s.src = `https://webforms.pipedrive.com/f/loader`;
                                s.async = true;
                                document.body.appendChild(s);
                              }, 0);
                            },
                          )
                        }
                      >
                        <FormattedMessage {...messages.btnEnterprisePlan} />
                      </Button>

                      <A
                        href={PRICING_URL}
                        target="_blank"
                        className="mt-auto pt-3 mx-auto d-flex justify-content-center align-items-center"
                      >
                        <FormattedMessage {...messages.btnSeeFeatures} />
                        <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
                      </A>
                    </div>
                  </div>
                </PlanCard>
              </Col>
            ))}
          </Row>
        )}

        <div className="w-100 d-flex justify-content-end">
          <Button
            type="button"
            className="btn btn-link d-flex align-items-center"
            onClick={this.handleSubscriptionPlanCloseModal}
            style={{
              color: `rgba(91, 113, 145, 1)`,
            }}
          >
            <FormattedMessage {...messages.btnContinueFreePlan} />
            <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
          </Button>
        </div>

        <div className="w-100 d-flex justify-content-center mt-4">
          <Button
            type="button"
            className="btn btn-link btn-lg d-flex align-items-center"
            onClick={() => window.open('https://www.notchup.com/pricing?comparePlans=true', '_blank')}
          >
            Want to see all feature side-by-side? Compare plans
            <SVGIcon src={rightAngleIcon} iconColor={`rgb(${primaryNew})`} className="ms-1" />
          </Button>
        </div>
        <ContactModal
          isOpen={showModal}
          contentLabel="crop"
          className={`modal-dialog ${showModal === 'compare-plans' ? 'upgrade-dialog' : ''}`}
          style={{ overlay: { zIndex: 12 } }}
          shouldCloseOnOverlayClick // Allow closing by clicking outside
          onRequestClose={() => this.setState({ showModal: false })}
          ariaHideApp={false}
          ariaModal
        >
          <div className="modal-content relative">
            <div className={`modal-body ${showModal === 'compare-plans' ? 'pricing-compare-div' : ''}`} id="pricing-compare-div">
              {showModal === 'contact-form' && (
                <>
                  <div
                    className="pipedriveWebForms"
                    data-pd-webforms="https://webforms.pipedrive.com/f/cBaJclbQbGV3xjaWcY8tjFMLlYPzihcz8lZ8iDuJseYMzZSV6D5V7FetZs5yhzZKvh"
                  />
                </>
              )}
              <Button
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '50px',
                }}
                className="btn btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showModal: false }); //
                }}
              >
                <SVGIcon className="" src={errorIcon} width={20} height={20} />
              </Button>
            </div>
          </div>
        </ContactModal>
      </ModalWrapper>
    );
  }
}

SubscriptionModal.defaultProps = {
  history: {},
  invalid: true,
  loading: false,
  responseSuccess: false,
  responseError: false,
  successPath: '',
  cancelPath: '',
  isNormalUpgrade: false,
};

SubscriptionModal.propTypes = {
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  responseSuccess: PropTypes.bool,
  responseError: PropTypes.bool,
  successPath: PropTypes.string,
  cancelPath: PropTypes.string,
  isNormalUpgrade: PropTypes.bool,
};

export default SubscriptionModal;
