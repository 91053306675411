/*
 * VerifyLinkedIn Messages
 *
 * This contains all the text for the VerifyLinkedIn component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.components.VerifyLinkedIn';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'LinkedIn login',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'LinkedIn login',
  },
  headingVerifyLinkedin: {
    id: `${scope}.headingVerifyLinkedin`,
    defaultMessage: 'Verifying your login',
  },
  textIntro: {
    id: `${scope}.textIntro`,
    defaultMessage: 'We are verifying your Linkedin login, we will log you in automatically very soon',
  },
  signInButtonText: {
    id: `${scope}.signInButtonText`,
    defaultMessage: 'Back to Log in',
  },
});
