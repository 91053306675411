/*
 * Dashboard Messages
 *
 * This contains all the text for the Dashboard component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.planYourCareer';

export default defineMessages({
  bannerMessage: {
    id: `${scope}.bannerMessage`,
    defaultMessage: 'Check your career graph to go beyond the resume and navigate the roadmap to reach your desired goals.',
  },
  bannerCTA: {
    id: `${scope}.bannerCTA`,
    defaultMessage: 'Plan your career',
  },
  completeProfile: {
    id: `${scope}.completeProfile`,
    defaultMessage: 'Complete your profile',
  },
  completeProfileDescription: {
    id: `${scope}.completeProfileDescription`,
    defaultMessage:
      'To view your potential career paths, kindly enter details in about you, qualification, experience & work preferences section of your profile using the below action',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your Career Plan',
  },
  metaTitle: {
    id: `${scope}.metaTitle`,
    defaultMessage: 'Your Career Plan',
  },
  careerGraphDescription: {
    id: `${scope}.careerGraphDescription`,
    defaultMessage: `Our AI model has generated below career graph based on your current role, profile, skills & desired role. Now, you can click on each node to see 'How do you match for the selected role', 'Set as Target Role' or update your target role using the 'Edit' action.`,
  },
  planYourCareer: {
    id: `${scope}.planYourCareer`,
    defaultMessage: `Plan your Career`,
  },
  careerPlanOverviewDescription: {
    id: `${scope}.careerPlanOverviewDescription`,
    defaultMessage: `Share your goals, and we’ll recommend courses to upskill and boost your future.`,
  },
  careerPlanOverviewTitle: {
    id: `${scope}.careerPlanOverviewTitle`,
    defaultMessage: `Plan your career here!`,
  },
});
