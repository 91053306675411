/**
 * This class used for Store value on localStorage of browser.
 * @author Innovify
 */

// Encoding string to base64 with special characters
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.

  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      // eslint-disable-next-line prefer-template
      return String.fromCharCode('0x' + p1);
    }),
  );
}

// Decoding base64  to original string
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      // eslint-disable-next-line prefer-template
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

const storageKeys = [
  'profileId',
  'signupStep',
  'apiSignupStep',
  'userVersion',
  'user',
  'recentSearch',
  'jwtToken',
  'location',
  'unAuthorize',
  'userPhoneCountry',
  'registerType',
  'userEmail',
  'userType',
  'agencyLogo',
  'pageNumber',
  'userPhoneNumber',
  'profilePicture',
  'firstName',
  'lastName',
  'userPhone',
  'Notification',
  'NotificationType',
  'talentProjectPageNumber',
  'talentProjectFilter',
  'clientProjectPageNumber',
  'clientProjectStatus',
  'isPaymentSkipped',
  'agencyMyTeamPageNumber',
  'adminAgencyPageNumber',
  'adminAgencyFilterObject',
  'adminBriefDetailID',
  'clientsPageNumber',
  'recruitersPageNumber',
  'adminAmbassadorSearch',
  'interviewFilterObject',
  'interviewPageNumber',
  'adminProjectDetailsBriefPageNumber',
  'projectFilterObject',
  'projectPageNumber',
  'talentFilterObject',
  'talentPageNumber',
  'agencyQuotePage',
  'selectedRoleType',
  'filterObject',
  'proxyType',
  'proxyToken',
  'talentBriefPage',
  'talentBriefFilter',
  'clientBriefDetailsPageNumber',
  'clientBriefPage',
  'clientBriefFilter',
  'clientID',
  'adminBriefPage',
  'adminTalentSearch',
  'adminAgencySearch',
  'adminClientSearch',
  'adminRecruiterSearch',
  'adminAmbassadorSearch',
  'isLoginViaAdmin',
  'userBillingDetailsObj',
  'hamburgerToggle',
  'RecruiterBriefPage',
  'recruiterId',
  'ambassadorId',
  'timesheetPage',
  'subscribed',
  'subscriptionPlan',
  'teamFromDashboard',
  'restrictedActions',
  'validForm',
  'isSuperAdmin',
  'draftJobPost',
  'userBillingDetails',
  'teamsPageNumber',
  'talentsPageNumber',
  'talentTeamSort',
  'talentTeamPageNumber',
  'talentProjectDetailsBriefPageNumber',
  'talentPrimaryRole',
  'talentJobListPageNumber',
  'talentId',
  'subscriptionPlanDetails',
  'stepCompleted',
  'projectDetailsJobBriefPageNumber',
  'projectDetailId',
  'employerUsersPageNumber',
  'draftJobPostId',
  'clientTeamTalentSort',
  'clientTalentPageNumber',
  'clientProjectSort',
  'clientBrand',
  'appVersion',
  'ambassadorsPageNumber',
  'ambassadorClientsSearch',
  'agencyProjectsSort',
  'agencyProjectsPageNumber',
  'agencyProjectsFilter',
  'adminProjectSearch',
  'adminInterviewSearch',
  'adminBriefSort',
  'ONE_TIME_SUBSCRIPTION_PRICE',
  'ClientTimeZone',
  'AgencyBriefPage',
  'AgencyBriefFilter',
  'clientCompanyDetails',
  'clientCompany',
  'clientCompanyName',
  'isClaimer',
  'clientUserRole',
  'clientBasicDetails',
  'clientProjectPageNumber',
  'clientProjectStatus',
  'clientProjectSort',
  'clientInterviewRequestsPageNumber',
  'adminInterviewRequestsPageNumber',
  'clientInterviewRequestsFilterObject',
  'adminInterviewRequestsFilterObject',
  'clientInterviewRequestsSearch',
  'adminInterviewRequestsSearch',
  'talentInterviewRequestsPageNumber',
  'talentInterviewRequestsFilterObject',
  'talentInterviewRequestsSearch',
  'adminClientSearch',
  'clientsPageNumber',
  'clientsFilterObject',
  'userCompleteDetails',
  'selectedProjectId',
  'talentRoles',
];

const StorageService = {
  /**
   * check value exists on localStorage of browser.
   * @author Innovify
   * @Developer Ankit Gujarati
   */
  exists(key) {
    return this.get(key);
  },
  /**
   * set value from localStorage of browser.
   * @author Innovify
   * @Developer Ankit Gujarati
   */
  set(key, value, opts = {}) {
    let storedValue = value;
    if (opts.stringify) {
      storedValue = JSON.stringify(storedValue);
    }
    if (opts.hash) {
      storedValue = b64EncodeUnicode(storedValue);
    }
    try {
      if (opts.keepMe) {
        const d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${key}=${JSON.stringify({
          opts,
          body: storedValue,
        })};${expires};path=/`;
      } else {
        document.cookie = `${key}=${JSON.stringify({
          opts,
          body: storedValue,
        })};path=/`;
      }
    } catch (err) {
      throw err;
    }
  },
  /**
   * get value from localStorage of browser.
   * @author Innovify
   */
  get(key) {
    // if (!this.exists(key)) return false;
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const data = JSON.parse(c.substring(name.length, c.length));
        let { body } = data;
        if (data.opts && data.opts.hash) {
          body = b64DecodeUnicode(body);
        }
        if (data.opts && data.opts.stringify) {
          body = JSON.parse(body);
        }
        return body;
      }
    }
    return false;
    /* const data = JSON.parse(localStorage.getItem(key));
    let { body } = data;
    if (data.opts && data.opts.hash) body = base.decode(body);
    if (data.opts && data.opts.stringify) body = JSON.parse(body);
    return body; */
  },
  /**
   * delete value from localStorage of browser.
   * @author Innovify
   */
  delete(key, opts = {}) {
    const d = new Date();
    d.setTime(d.getTime() + 0);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${JSON.stringify({
      opts,
      body: '',
    })};${expires};path=/`;
    // localStorage.removeItem(key);
  },
  /**
   * clear localStorage of browser.
   * @author Innovify
   */
  clear() {
    storageKeys.forEach((storageKey) => {
      this.delete(storageKey);
      localStorage.removeItem(storageKey);
    });
  },
};

export default StorageService;
