import history from 'utils/history';
import StorageService from 'utils/StorageService';
import Emitter from 'utils/emitter';

const storageKeys = [
  'firstName',
  'userType',
  'restrictedActions',
  'registerType',
  'clientBrand',
  'signupStep',
  'agencyLogo',
  'profilePicture',
  'isPaymentSkipped',
  'clientToken',
  'recruiterToken',
  'agencyToken',
  'talentToken',
  'proxyType',
  'proxyToken',
  'profileId',
  'userId',
  'ClientTimeZone',
  'clientProjectPageNumber',
  'clientProjectStatus',
  'clientProjectSort',
  'clientInterviewRequestsPageNumber',
  'adminInterviewRequestsPageNumber',
  'clientInterviewRequestsFilterObject',
  'adminInterviewRequestsFilterObject',
  'clientInterviewRequestsSearch',
  'adminInterviewRequestsSearch',
  'talentInterviewRequestsPageNumber',
  'talentInterviewRequestsFilterObject',
  'talentInterviewRequestsSearch',
  'adminClientSearch',
  'clientsPageNumber',
  'clientsFilterObject',
  'clientOnboarded',
  'availability',
  'userCompleteDetails',
  'selectedProjectId',
  'talentRoles',
  'clientBriefFilter',
  'clientUserRole',
  'clientBasicDetails',
];

export const handleBackToAdmin = () => {
  storageKeys.forEach((storageKey) => {
    StorageService.delete(storageKey);
    localStorage.removeItem(storageKey);
  });
  const adminEmail = StorageService.get('adminEmail');
  if (adminEmail) {
    StorageService.set('userEmail', adminEmail);
    StorageService.delete('adminEmail');
  }
  StorageService.set('userType', 4, { hash: true });
  Emitter.emit(Emitter.EVENTS.HAMBURGER_TOGGLE, false);
  Emitter.emit('proxyBackToAdmin', true);
  history.replace('/admin/');
};
