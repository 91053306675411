import styled from 'styled-components';
import { primaryNew, primaryDarkNew, white, darkGray } from 'themes/variables';
import media from 'themes/media';

export const FixedSidebar = styled.div`
  position: fixed;
  background-color: rgb(${white});
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-right: 1px solid rgba(${primaryNew}, 0.1);
  z-index: 9;
  transition: all 0.3s ease-in-out;

  ${media.medium`
    width: 265px;
  `}

  .scrollbar-container {
    padding: 25px 0;
  }

  .cm-logo {
    margin-left: 23px;
  }

  .dot-sidebar {
    display: none;
  }

  &.sidebar-close {
    width: 0px;
    height: 0px;
    transition: all 0.3s ease-out;

    .dot-sidebar {
      display: block;
      width: 12px;
      height: 12px;
      background: rgb(${primaryNew});
      position: absolute;
      border-radius: 100%;
      ${media.medium`
        top: 5px;
        right: 18px;
      `}
    }

    ${media.medium`
      width: 90px;
      height: 100vh
    `}

    .nav {
      align-items: center;
      .nav-item {
        padding: 0;

        .nav-link {
          .menu-option {
            display: none;
          }

          .icon {
            margin-right: 0;

            .new-badge {
              display: block;
            }
          }
        }
      }
    }
  }

  ${media.extraLarge`
    width: 260px;
  `};

  .nav {
    flex-direction: column;
    margin-top: 35px;

    .nav-item {
      margin-bottom: 0;

      .nav-link {
        display: flex;
        align-items: center;
        padding: 0;
        color: rgba(${primaryDarkNew}, 0.3);
        font-family: 'GT-Walsheim-Pro-Regular';
        font-size: 20px;
        position: relative;
        padding: 13px 33px;
        min-height: 50px;

        ${media.medium`
            font-size: 16px;
        `};

        &:hover,
        &.active {
          cursor: pointer;
          color: rgb(${darkGray});
          .icon {
            &.referral-icon {
              path {
                fill: rgba(${primaryNew});
                stroke: none;
              }
            }
            g {
              opacity: 1;
            }
            path,
            circle {
              stroke: rgb(${primaryNew});
            }
          }
        }
        &.active {
          &:after {
            content: '';
            width: 3px;
            height: 100%;
            background-color: rgb(${primaryNew});
            position: absolute;
            left: -2px;
            top: 0;
            bottom: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
          color: rgb(${primaryNew});
        }

        .icon {
          display: flex;
          margin-right: 16px;
          position: relative;

          .new-badge {
            display: none;
          }

          &.referral-icon {
            path {
              fill: rgba(${primaryDarkNew}, 0.3);
              stroke: none;
            }
          }
          svg {
            width: 21px;
            height: 21px;
          }

          ${media.medium`
            svg {
              width: 15px;
              height: 15px;
            }
          `};

          ${media.extraLarge`
            svg {
              width: 20px;
              height: 20px;
            }
          `};

          path,
          circle {
            stroke: rgba(${primaryDarkNew}, 0.3);
          }
        }

        .menu-option {
          position: relative;
          display: flex;
          align-items: center;
        }
        .dot-sidebar {
          display: inline-flex;
          width: 12px;
          height: 12px;
          background: rgb(${primaryNew});
          border-radius: 100%;
          margin-left: 5px;
        }
        .fill-none {
          svg {
            circle {
              fill: none;
            }
          }
        }
      }
    }
  }
`;

export const TourItemContainer = styled('div')`
  top: 62px;
  left: 236px;
  border-radius: 10px;

  svg {
    width: 30px;
    height: 30px;
  }

  button {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    &.skip {
      color: rgba(${primaryDarkNew}, 0.7);
    }
  }
`;

export const DotSidebar = styled('div')``;
