/*
 * Validate your skills Messages
 *
 * This contains all the text for the Validate your skills component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.validateSkills';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Verify your skills',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Verify your skills',
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Get verified',
  },
  titleDescription: {
    id: `${scope}.titleDescription`,
    defaultMessage:
      'Let’s underscore your core skills by formally verifying them. Complete our online tests covering your top 7 skills. Each assessment lasts 10-20 mins, and you will have only one attempt. You may retake the test after 45 days if you don’t meet the required score or want to increase your score.',
  },
  scheduleBtn: {
    id: `${scope}.scheduleBtn`,
    defaultMessage: 'Schedule 1:1 interview',
  },
  labelTopSkills: {
    id: `${scope}.labelTopSkills`,
    defaultMessage: 'Top skills',
  },
  labelTeamWorkingStyleTests: {
    id: `${scope}.labelTeamWorkingStyleTests`,
    defaultMessage: 'Team Working Style',
  },
  labelOptionalOtherTests: {
    id: `${scope}.labelOptionalOtherTests`,
    defaultMessage: 'Optional other tests',
  },
  labelPsychometric: {
    id: `${scope}.labelPsychometric`,
    defaultMessage: 'Psychometric',
  },
  tooltipSuccess: {
    id: `${scope}.tooltipSuccess`,
    defaultMessage: 'Scored more than 60% on the test for this skill',
  },
  tooltipWarning: {
    id: `${scope}.tooltipWarning`,
    defaultMessage: 'Scored more than 40% on the test for this skill',
  },
  tooltipError: {
    id: `${scope}.tooltipError`,
    defaultMessage: 'Failed to clear the test for this skill',
  },
  tooltipDefault: {
    id: `${scope}.tooltipDefault`,
    defaultMessage: 'Talent has not yet taken the test for this skill',
  },
  testIntruction: {
    id: `${scope}.testIntruction`,
    defaultMessage:
      'Its single take only. Once you click “Start test” button, your timer will start and you won’t be able to restart or re-take the test.Make sure you are prepared for the test and have a stable Internet connection.',
  },
  testMaxScoreInfo: {
    id: `${scope}.testMaxScoreInfo`,
    defaultMessage: 'Score 40% minimum to pass',
  },
  testQueTypeInfo: {
    id: `${scope}.testQueTypeInfo`,
    defaultMessage: '15-30 multiple choice questions',
  },
  titleInterviewSlot: {
    id: `${scope}.titleInterviewSlot`,
    defaultMessage: 'Invterview Slots',
  },
  interviewSlotInfo: {
    id: `${scope}.interviewSlotInfo`,
    defaultMessage: 'Select date and time when you are available for the 1:1 interview with our HR.',
  },
  interviewSlotNewInfo: {
    id: `${scope}.interviewSlotNewInfo`,
    defaultMessage: `You can take this test as many times as you like. Once you click “Start test” button, the test will load in this browser window. When you done with the test, simply click “Complete test“ button in the top right corner to generate your assessment score.`,
  },
  retakeTestBetterScore: {
    id: `${scope}.retakeTestBetterScore`,
    defaultMessage: `You have achieved the minimum required score to apply for jobs. But you can still score better by retaking the test after 45 days.`,
  },
  minmumScoreMsg: {
    id: `${scope}.minmumScoreMsg`,
    defaultMessage: `You didn’t achieve the minimum required score. You will be able to retake this test on `,
  },
});
