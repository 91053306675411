import React from 'react';
import { LoaderIcon } from 'components/SkeletonLoader';
import { primaryDarkNew } from 'themes/variables';

const LoaderScreen = ({ width, height }) => (
  <div
    style={{
      position: 'relative',
      width,
      height,
    }}
    data-testid="loader-screen"
  >
    <LoaderIcon width={width} height={height} square />
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: `rgb(${primaryDarkNew})`,
        fontWeight: 'bold',
        fontSize: '18px',
        textAlign: 'center',
        width: '80%',
      }}
    >
      Relax, while we analyze your profile and plan a career path for you!
    </div>
  </div>
);

export default LoaderScreen;
