import React from 'react';
import { yearsOfExperienceArray, employmentTypeArray, roles, companyTypeArray } from 'containers/App/constants';
import { assignmentArray, teamPreferenceArray } from 'containers/TalentListingPage/constants';
import { get, isEmpty } from 'lodash';
import { briefFiltersKeys, projectPreference } from './constants';
import { Field } from 'redux-form';
import { renderFieldoptCheckbox } from 'utils/Fields';
import { P } from 'components';

export const getFiltersURL = (data) =>
  data.length > 0
    ? data
        .map((selected) => selected.label)
        .map((i) => i)
        .join(',')
    : '';

export const getFiltersValueURL = (data) =>
  data.length > 0
    ? data
        .map((selected) => selected.value)
        .map((i) => i)
        .join(',')
    : '';

// Get filtered options list for default briefFilter
export const getFilteredOptions = (arr, resObj, keyName, defaultLableKey = 'label', isStrCompare = false) => {
  if (isStrCompare) {
    return arr
      .filter((ele) => ele.value === get(resObj, keyName, ''))
      .map((item) => ({
        value: item.value,
        label: get(item, defaultLableKey, ''),
      }));
  }
  return arr
    .filter((ele) => get(resObj, keyName, []).includes(ele.value))
    .map((item) => ({
      value: item.value,
      label: item.label,
    }));
};

export const setDefaultRoleBasedBriefFilter = (response) => {
  if (!isEmpty(response)) {
    // find brief filters keys from API resopnse
    const keyLists = Object.entries(response).filter((ele) => briefFiltersKeys.includes(ele[0]));
    const newResponseData = Object.fromEntries(keyLists);

    // find roles
    const newRolesArry = getFilteredOptions(roles, newResponseData, 'primaryRole', 'name', true);

    // find yearsOfExperience
    const newExpertiseArray = getFilteredOptions(yearsOfExperienceArray, newResponseData, 'yearsOfExperience', 'label', true);

    // find employmentType
    const newContractTypeArray = getFilteredOptions(employmentTypeArray, newResponseData, 'employmentType');

    // find workPreference
    const newWorkPrefArray = getFilteredOptions(projectPreference, newResponseData, 'workPreference');

    // find assignments
    const newAssignmentsArray = getFilteredOptions(assignmentArray, newResponseData, 'assignments');

    // find companyType
    const newCompanySizeArray = getFilteredOptions(companyTypeArray, newResponseData, 'companyType');

    // find teamPreference
    const newTeamPrefArray = getFilteredOptions(teamPreferenceArray, newResponseData, 'teamPreference');

    const newTalentBriefFilter = {
      roleArray: newRolesArry || [],
      expertiseArray: [],
      contractTypeArray: [],
      projectDuration: '',
      workPrefArray: [],
      assignmentsArray: [],
      companySizeArray: [],
      teamPrefArray: [],
    };

    localStorage.setItem('talentBriefFilter', JSON.stringify(newTalentBriefFilter));
  }
};

export const applyJobsCheckBox = ({ name = '', label = '', messageClass = '', messageOpacity = '0.5' }) => (
  <Field
    name={name}
    type="checkbox"
    component={renderFieldoptCheckbox}
    disabled={false}
    message={
      <P className={`p14 mb-0 ${messageClass}`} opacityVal={messageOpacity}>
        Show only '{label}' jobs
      </P>
    }
    labelClass="mb-0"
    checkmarkClass="top-0"
  />
);

export const externalJobMapping = {
  1: { manualApply: true, quickApply: false },
  0: { manualApply: false, quickApply: true },
  null: { manualApply: false, quickApply: false },
};
