import {
  TALENT_PARTNER,
  AGENCY,
  TALENT,
  CLIENT,
  ADMIN,
  projectsIcon,
  userIcon,
  calendarWithTimeIcon,
  quotesIcon,
  dollarIcon,
  teamIcon,
  clientsIcon,
  dashboardIcon,
  textFileIcon,
  builidingIcon,
  searchJobsIcon,
  recruiterIcon,
  ambassadorIcon,
  interviewIcon,
  AMBASSADOR,
  CLIENT_DASHBOARD,
  perksandpartnershipIcon,
  companiesIcon,
  // walletIcon,
  winnerCupIcon,
  professionIcon2,
  upskillIcon,
  careerGraphIcon,
} from 'containers/App/constants';

export const clientNavPages = [
  {
    title: 'Dashboard',
    pathName: CLIENT_DASHBOARD,
    icon: dashboardIcon,
    paths: ['/', `${CLIENT}`, `${CLIENT}/`, CLIENT_DASHBOARD, `${CLIENT_DASHBOARD}/`],
    isBadgeConfig: false,
    key: 'dashboard',
  },
  {
    title: 'Find Teams/Talents',
    pathName: `${CLIENT}/listings`,
    icon: searchJobsIcon,
    paths: [`${CLIENT}/talent-listing`, `${CLIENT}/talent- listing/`, `${CLIENT}/listings`, `${CLIENT}/listings/`],
    isBadgeConfig: false,
    key: 'listings',
  },
  {
    title: 'Competitions',
    pathName: `${CLIENT}/competitions`,
    icon: winnerCupIcon,
    paths: [`${CLIENT}/competitions`, `${CLIENT}/competitions`],
    isBadgeConfig: true,
    key: 'competitions',
    badgeKey: 'competitions',
  },
  {
    title: 'Projects',
    pathName: `${CLIENT}/projects`,
    icon: projectsIcon,
    paths: [`${CLIENT}/projects`, `${CLIENT}/projects/`],
    isBadgeConfig: false,
    key: 'projects',
  },
  {
    title: 'Job Briefs',
    pathName: `${CLIENT}/job-briefs`,
    icon: textFileIcon,
    paths: [`${CLIENT}/job-briefs`, `${CLIENT}/job-briefs/`],
    isBadgeConfig: false,
    key: 'jobBriefs',
  },
  {
    title: 'Interviews',
    pathName: `${CLIENT}/interviews`,
    icon: interviewIcon,
    paths: [`${CLIENT}/interviews`, `${CLIENT}/interviews/`],
    isBadgeConfig: false,
    key: 'interviews',
  },
  {
    title: 'Talents',
    pathName: `${CLIENT}/talents`,
    icon: clientsIcon,
    paths: [`${CLIENT}/talents`, `${CLIENT}/talents`],
    isBadgeConfig: false,
    key: 'talents',
  },
  {
    title: 'Timesheets',
    pathName: `${CLIENT}/timesheets`,
    icon: calendarWithTimeIcon,
    paths: [`${CLIENT}/timesheets`, `${CLIENT}/timesheets`],
    isBadgeConfig: true,
    key: 'timesheets',
    badgeKey: 'newTimesheet',
  },
  {
    title: 'Perks',
    pathName: `${CLIENT}/perks`,
    icon: perksandpartnershipIcon,
    paths: [`${CLIENT}/perks`, `${CLIENT}/perks/`],
    isBadgeConfig: true,
    key: 'perks',
    badgeKey: 'perks',
  },
  /* For Future use
  {
    title: 'Bill',
    pathName: `${CLIENT}/billing`,
    icon: billIcon,
    paths: [`${CLIENT}/billing`, `${CLIENT}/billing`],
    isBadgeConfig: false,
    key: 'billing',
  },
  {
    title: 'Contracts',
    pathName: `${CLIENT}/contracts`,
    icon: contractIcon,
    paths: [`${CLIENT}/contracts`, `${CLIENT}/contracts`],
    isBadgeConfig: false,
    key: 'contracts',
  },
  {
    title: 'Payments',
    pathName: `${CLIENT}/payments`,
    icon: paymentIcon,
    paths: [`${CLIENT}/payments`, `${CLIENT}/payments`],
    isBadgeConfig: false,
    key: 'payments',
  },
  */
];

export const adminNavPages = [
  {
    title: 'Dashboard',
    pathName: `${ADMIN}/dashboard`,
    icon: dashboardIcon,
    paths: ['/', `${ADMIN}`, `${ADMIN}/`, `${ADMIN}/dashboard`, `${ADMIN}/dashboard/`],
    isBadgeConfig: false,
    key: 'dashboard',
  },
  {
    title: 'Projects',
    pathName: `${ADMIN}/projects`,
    icon: projectsIcon,
    paths: [`${ADMIN}/projects`, `${ADMIN}/projects`],
    isBadgeConfig: false,
    key: 'projects',
  },
  {
    title: 'Job Briefs',
    pathName: `${ADMIN}/job-briefs`,
    icon: textFileIcon,
    paths: [`${ADMIN}/job-briefs`, `${ADMIN}/job-briefs`],
    isBadgeConfig: false,
    key: 'jobBriefs',
  },
  {
    title: 'Interviews',
    pathName: `${ADMIN}/interviews`,
    icon: interviewIcon,
    paths: [`${ADMIN}/interviews`, `${ADMIN}/interviews`],
    isBadgeConfig: false,
    key: 'interviews',
  },
  {
    title: 'Quotes',
    pathName: `${ADMIN}/quotes`,
    icon: quotesIcon,
    paths: [`${ADMIN}/quotes`, `${ADMIN}/quotes`],
    isBadgeConfig: false,
    key: 'quotes',
  },
  {
    title: 'Companies',
    pathName: `${ADMIN}/company-listing`,
    icon: companiesIcon,
    paths: [`${ADMIN}/company-listing`, `${ADMIN}/company-listing`],
    isBadgeConfig: false,
    key: 'companies',
  },
  {
    title: 'Talents',
    pathName: `${ADMIN}/talents`,
    icon: clientsIcon,
    paths: [`${ADMIN}/talents`, `${ADMIN}/talents`],
    isBadgeConfig: false,
    key: 'talents',
  },
  {
    title: 'Timesheets',
    pathName: `${ADMIN}/timesheets`,
    icon: calendarWithTimeIcon,
    paths: [`${ADMIN}/timesheets`, `${ADMIN}/timesheets`],
    isBadgeConfig: false,
    key: 'timesheets',
  },
  {
    title: 'Clients',
    pathName: `${ADMIN}/clients`,
    icon: userIcon,
    paths: [`${ADMIN}/clients`, `${ADMIN}/clients`],
    isBadgeConfig: false,
    key: 'clients',
  },
  {
    title: 'Agencies',
    pathName: `${ADMIN}/agencies`,
    icon: builidingIcon,
    paths: [`${ADMIN}/agencies`, `${ADMIN}/agencies`],
    isBadgeConfig: false,
    key: 'agencies',
  },
  {
    title: 'Referrals',
    pathName: `${ADMIN}/referrals`,
    icon: dollarIcon,
    paths: [`${ADMIN}/referrals`, `${ADMIN}/referrals`],
    isBadgeConfig: false,
    key: 'referrals',
  },
  {
    title: 'Talent partners',
    pathName: `${ADMIN}/talent-partners`,
    icon: recruiterIcon,
    paths: [`${ADMIN}/talent-partners`, `${ADMIN}/talent-partners`],
    isBadgeConfig: false,
    key: 'talentPartners',
  },
  {
    title: 'Ambassadors',
    pathName: `${ADMIN}/ambassadors`,
    icon: ambassadorIcon,
    paths: [`${ADMIN}/ambassadors`, `${ADMIN}/ambassadors`],
    isBadgeConfig: false,
    key: 'ambassadors',
  },
];

export const talentNavPages = ({ registerType, apiSignupStep, availability = true }) => [
  {
    title: 'Dashboard',
    pathName: `${TALENT}/dashboard`,
    icon: dashboardIcon,
    paths: ['/', `${TALENT}`, `${TALENT}/`, `${TALENT}/dashboard`, `${TALENT}/dashboard/`],
    isBadgeConfig: false,
    key: 'dashboard',
    hidden: false,
  },
  {
    title: 'Jobs',
    pathName: `${TALENT}/job-briefs`,
    icon: searchJobsIcon,
    paths: [`${TALENT}/job-briefs`, `${TALENT}/job-briefs`],
    isBadgeConfig: true,
    key: 'jobBriefs',
    badgeKey: 'newBrief',
    hidden: !availability,
  },
  {
    title: 'Competitions',
    pathName: `${TALENT}/competitions`,
    icon: winnerCupIcon,
    paths: [`${TALENT}/competitions`, `${TALENT}/competitions`],
    isBadgeConfig: true,
    key: 'competitions',
    hidden: false,
    badgeKey: 'newCompetition',
  },
  {
    title: 'Projects',
    pathName: `${TALENT}/my-projects`,
    icon: projectsIcon,
    paths: [`${TALENT}/my-projects`, `${TALENT}/my-projects`],
    isBadgeConfig: false,
    key: 'myProjects',
    hidden: false,
  },
  {
    title: 'Timesheets',
    pathName: `${TALENT}/timesheets`,
    icon: calendarWithTimeIcon,
    paths: [`${TALENT}/timesheets`, `${TALENT}/timesheets`],
    isBadgeConfig: true,
    key: 'timesheets',
    hidden: false,
    badgeKey: 'newTimesheet',
  },
  {
    title: 'Technical Tests',
    pathName: `${TALENT}/validate-skills`,
    icon: professionIcon2,
    paths: [`${TALENT}/validate-skills`, `${TALENT}/validate-skills`],
    isBadgeConfig: true,
    key: 'validate-skills',
    hidden: false,
    badgeKey: 'validate-skills',
  },
  {
    title: 'Interviews',
    pathName: `${TALENT}/interviews`,
    icon: interviewIcon,
    paths: [`${TALENT}/interviews`, `${TALENT}/interviews/`],
    isBadgeConfig: false,
    key: 'interviews',
    hidden: !availability,
  },
  {
    title: 'Career Plan',
    pathName: `${TALENT}/career-plan-overview`,
    icon: careerGraphIcon,
    paths: [`${TALENT}/career-plan-overview`, `${TALENT}/career-plan`],
    isBadgeConfig: false,
    key: 'career-plan-overview',
    iconClass: 'fill-none',
    hidden: false,
  },
  {
    title: 'Courses',
    pathName: `${TALENT}/courses`,
    icon: upskillIcon,
    paths: [`${TALENT}/courses`],
    isBadgeConfig: false,
    key: 'courses',
    iconClass: 'fill-none',
    hidden: false,
  },
  // {
  //   title: 'Wallet',
  //   pathName: `${TALENT}/wallet`,
  //   icon: walletIcon,
  //   paths: [`${TALENT}/wallet`, `${TALENT}/wallet/`],
  //   isBadgeConfig: false,
  //   key: 'wallet',
  //   hidden: registerType !== 'freelancer' && apiSignupStep !== 7,
  // },
  {
    title: 'Refer and Earn',
    pathName: `${TALENT}/referrals`,
    icon: dollarIcon,
    paths: [`${TALENT}/referrals`, `${TALENT}/referrals`],
    isBadgeConfig: false,
    key: 'referrals',
    hidden: false,
  },
  {
    title: 'Teams',
    pathName: `${TALENT}/my-teams`,
    icon: teamIcon,
    paths: [`${TALENT}/my-teams`, `${TALENT}/my-teams`],
    isBadgeConfig: false,
    key: 'myTeams',
    hidden: false,
  },
  {
    title: 'Perks',
    pathName: `${TALENT}/perks`,
    icon: perksandpartnershipIcon,
    paths: [`${TALENT}/perks`, `${TALENT}/perks/`],
    isBadgeConfig: true,
    key: 'perks',
    hidden: false,
  },
  /* For Future use
  {
    title: 'Invoices',
    pathName: `${TALENT}/invoices`,
    icon: billIcon,
    paths: [`${TALENT}/invoices`, `${TALENT}/invoices`],
    isBadgeConfig: false,
    key: 'invoices',
  },
  {
    title: 'Knowledge base',
    pathName: `${TALENT}/knowledge-base`,
    icon: booksIcon,
    paths: [`${TALENT}/knowledge-base`, `${TALENT}/knowledge-base`],
    isBadgeConfig: false,
    key: 'knowledgeBase',
  },
  {
    title: 'Community',
    pathName: `${TALENT}/community`,
    icon: communityIcon,
    paths: [`${TALENT}/community`, `${TALENT}/community`],
    isBadgeConfig: false,
    key: 'community',
  },
  {
    title: 'Career Paths',
    pathName: `${TALENT}/career-paths`,
    icon: briefcaseIcon,
    paths: [`${TALENT}/career-paths`, `${TALENT}/career-paths`],
    isBadgeConfig: false,
    key: 'careerPaths',
  },
  {
    title: 'Learning Development',
    pathName: `${TALENT}/learning-development`,
    icon: learningIcon,
    paths: [`${TALENT}/learning-development`, `${TALENT}/learning-development`],
    isBadgeConfig: false,
    key: 'learningDevelopment',
  },
  {
    title: 'Perks',
    pathName: `${TALENT}/perks`,
    icon: perksIcon,
    paths: [`${TALENT}/perks`, `${TALENT}/perks`],
    isBadgeConfig: false,
    key: 'perks',
  },
  {
    title: 'Wellbeing',
    pathName: `${TALENT}/wellbeing`,
    icon: heartIcon,
    paths: [`${TALENT}/wellbeing`, `${TALENT}/wellbeing`],
    isBadgeConfig: false,
    key: 'wellbeing',
  },
  */
];

export const agencyNavPages = [
  {
    title: 'Dashboard',
    pathName: `${AGENCY}/dashboard`,
    icon: dashboardIcon,
    paths: ['/', `${AGENCY}`, `${AGENCY}/`, `${AGENCY}/dashboard`, `${AGENCY}/dashboard/`],
    isBadgeConfig: false,
    key: 'dashboard',
  },
  {
    title: 'Resourcing',
    pathName: `${AGENCY}/job-briefs`,
    icon: textFileIcon,
    paths: [`${AGENCY}/job-briefs`, `${AGENCY}/job-briefs`],
    isBadgeConfig: false,
    key: 'jobBriefs',
  },
  {
    title: 'Quotes',
    pathName: `${AGENCY}/quotes`,
    icon: quotesIcon,
    paths: [`${AGENCY}/quotes`, `${AGENCY}/quotes`],
    isBadgeConfig: true,
    key: 'quotes',
    badgeKey: 'newQuote',
  },
  {
    title: 'Projects',
    pathName: `${AGENCY}/agency-projects`,
    icon: projectsIcon,
    paths: [`${AGENCY}/agency-projects`, `${AGENCY}/agency-projects`],
    isBadgeConfig: false,
    key: 'agencyProjects',
  },
  {
    title: 'Resources',
    pathName: `${AGENCY}/resources`,
    icon: clientsIcon,
    paths: [`${AGENCY}/resources`, `${AGENCY}/resources`],
    isBadgeConfig: false,
    key: 'myTeam',
  },
  {
    title: 'Timesheets',
    pathName: `${AGENCY}/timesheets`,
    icon: calendarWithTimeIcon,
    paths: [`${AGENCY}/timesheets`, `${AGENCY}/timesheets`],
    isBadgeConfig: false,
    key: 'timesheets',
  },

  /* For Future use
  {
    title: 'Invoices',
    pathName: `${AGENCY}/invoices`,
    icon: billIcon,
    paths: [`${AGENCY}/invoices`, `${AGENCY}/invoices`],
    isBadgeConfig: false,
    key: 'invoices',
  },
  {
    title: 'Statements',
    pathName: `${AGENCY}/agency-statements`,
    icon: textFileIcon,
    paths: [`${AGENCY}/agency-statements`, `${AGENCY}/agency-statements`],
    isBadgeConfig: false,
    key: 'agencyStatements',
  },
  {
    title: 'Planning',
    pathName: `${AGENCY}/agency-planning`,
    icon: billIcon,
    paths: [`${AGENCY}/agency-planning`, `${AGENCY}/agency-planning`],
    isBadgeConfig: false,
    key: 'agencyPlanning',
  },
  */
];

export const recruiterNavPages = [
  {
    title: 'Dashboard',
    pathName: `${TALENT_PARTNER}/dashboard`,
    icon: dashboardIcon,
    paths: ['/', `${TALENT_PARTNER}`, `${TALENT_PARTNER}/`, `${TALENT_PARTNER}/dashboard`, `${TALENT_PARTNER}/dashboard/`],
    isBadgeConfig: false,
    key: 'dashboard',
  },
  {
    title: 'Talents',
    pathName: `${TALENT_PARTNER}/talents`,
    icon: clientsIcon,
    paths: [`${TALENT_PARTNER}/talents`, `${TALENT_PARTNER}/talents`],
    isBadgeConfig: false,
    key: 'talents',
  },
  {
    title: 'Job Briefs',
    pathName: `${TALENT_PARTNER}/job-briefs`,
    icon: textFileIcon,
    paths: [`${TALENT_PARTNER}/job-briefs`, `${TALENT_PARTNER}/job-briefs/`],
    isBadgeConfig: false,
    key: 'jobBriefs',
  },
];

export const ambassadorNavPages = [
  {
    title: 'Dashboard',
    pathName: `${AMBASSADOR}/dashboard`,
    icon: dashboardIcon,
    paths: ['/', `${AMBASSADOR}`, `${AMBASSADOR}/`, `${AMBASSADOR}/dashboard`, `${AMBASSADOR}/dashboard/`],
    isBadgeConfig: false,
    key: 'dashboard',
  },
  {
    title: 'Talents',
    pathName: `${AMBASSADOR}/talents`,
    icon: clientsIcon,
    paths: [`${AMBASSADOR}/talents`, `${AMBASSADOR}/talents/`],
    isBadgeConfig: false,
    key: 'talents',
  },
  {
    title: 'Clients',
    pathName: `${AMBASSADOR}/clients`,
    icon: userIcon,
    paths: [`${AMBASSADOR}/clients`, `${AMBASSADOR}/clients/`],
    isBadgeConfig: false,
    key: 'clients',
  },
];
