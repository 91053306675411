import React from 'react';
import { TALENT, talentEmptyIcon } from 'containers/App/constants';

import EamptyScreen from 'components/EmptyScreens/EmptyScreenCard';
import { LinkMod } from 'components/A';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { PrivateGrid } from 'components';
import Content from 'components/Content';
import history from 'utils/history';

const TalentCareerPlanOverview = () => {
  const storedUserDetails = localStorage.getItem('userCompleteDetails');
  const currentUserData = storedUserDetails && storedUserDetails !== 'null' && JSON.parse(storedUserDetails);
  if (currentUserData?.data?.signupStep <= 5) {
    return (
      <PrivateGrid>
        <Content data-testid="plan-your-career-content-container">
          <EamptyScreen
            cardDescription={messages.completeProfileDescription}
            cardTitle={messages.completeProfile}
            icon={talentEmptyIcon}
            textContainersClasses="d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left"
            cardClasses="d-flex flex-column align-items-start align-items-sm-center p-40"
          >
            <LinkMod to={`${TALENT}/about-you`} className="btn btn-primary px-0 py-2 btn-sm mt-3 mt-md-0">
              <FormattedMessage {...messages.completeProfile} />
            </LinkMod>
          </EamptyScreen>
        </Content>
      </PrivateGrid>
    );
  }

  if (currentUserData?.data?.signupStep > 5 && currentUserData?.data?.careerPlan) {
    history.replace(`${TALENT}/career-plan`);
    return null;
  }

  return (
    <PrivateGrid>
      <Content data-testid="plan-your-career-content-container">
        <EamptyScreen
          cardDescription={messages.careerPlanOverviewDescription}
          cardTitle={messages.careerPlanOverviewTitle}
          icon={talentEmptyIcon}
          textContainersClasses="d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left"
          cardClasses="d-flex flex-column align-items-start align-items-sm-center p-40"
        >
          <LinkMod to={`${TALENT}/career-plan`} className="btn btn-primary px-0 py-2 btn-sm mt-3 mt-md-0">
            <FormattedMessage {...messages.planYourCareer} />
          </LinkMod>
        </EamptyScreen>
      </Content>
    </PrivateGrid>
  );
};

export default TalentCareerPlanOverview;
