import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { get } from 'lodash';
import { ListWithIcon, TestDetails, TestIcon } from './styles';
import { H3, P, SVGIcon } from 'components';
import { certificationsIcon, logoPlaceholder, noteIcon, watchIcon } from 'containers/App/constants';
import moment from 'moment';
import SVG from 'react-inlinesvg';
import ModalWrapper from 'components/ModalWrapper';
import messages from './messages';
import { checkRetakeTestEligibility, getIconBasedOnScore, getToolTipContentBasedOnScore } from './utils';

const TestModal = ({ onDiscard, onSubmit, selectedTestData = {}, isLoading = false, primaryDarkNew = '0, 0, 0' }) => {
  const {
    name = '',
    description = '',
    duration = '',
    hasCompletedTest = false,
    score = 0,
    dateToNextAttempt = '',
    url = '',
  } = selectedTestData;

  const isButtonDisabled = useMemo(
    () => (get(selectedTestData, 'hasCompletedTest', false) === false ? true : checkRetakeTestEligibility(selectedTestData)),
    [selectedTestData],
  );

  const handleStartTestSubmit = (e) => {
    onSubmit?.(url);
  };

  return (
    <ModalWrapper
      modalClassName="info-modal h-75"
      modalId="showTestModal"
      isOpen
      onDiscard={onDiscard}
      onSecondarySubmit={onDiscard}
      primaryBtnText="Start test"
      secondaryBtnText="Cancel"
      title=""
      onHandleSubmit={handleStartTestSubmit}
      modalBodyClass="pt-0"
      modalHeaderClass="pb-0"
      loading={isLoading}
      disabled={!isButtonDisabled}
    >
      <TestIcon className="mb-20">
        <SVG src={logoPlaceholder} />
      </TestIcon>
      <H3 className="mb-2">{name}</H3>
      <P className="p16 mb-0" opacityVal="0.5">
        {description}
      </P>
      <TestDetails>
        <ListWithIcon className="mb-2">
          <SVGIcon iconColor={`rgba(${primaryDarkNew}, 0.5)`} src={noteIcon} className="w-16" />
          <P className="p16 mb-0" opacityVal="0.5">
            <FormattedMessage {...messages.testQueTypeInfo} />
          </P>
        </ListWithIcon>
        <ListWithIcon className="mb-2">
          <SVGIcon iconColor={`rgba(${primaryDarkNew}, 0.5)`} src={watchIcon} className="w-16" />
          <P className="p16 mb-0" opacityVal="0.5">
            {duration} mins
          </P>
        </ListWithIcon>
        <ListWithIcon>
          {name !== 'Team Working Style Assessment' && name !== 'DISC Assessment' && (
            <>
              <SVGIcon iconColor={`rgba(${primaryDarkNew}, 0.5)`} src={certificationsIcon} className="w-16" />
              <P className="p16 mb-0" opacityVal="0.5">
                <FormattedMessage {...messages.testMaxScoreInfo} />
              </P>
            </>
          )}
        </ListWithIcon>
      </TestDetails>
      <ListWithIcon>
        {hasCompletedTest && (
          <>
            <span data-tooltip-id={`Tooltip_${selectedTestData?.id}`} className={'ms-auto'}>
              <SVG src={getIconBasedOnScore(score)} />
            </span>
            <ReactTooltip
              id={`Tooltip_${selectedTestData?.id}`}
              content={getToolTipContentBasedOnScore(score, moment(dateToNextAttempt).format('DD-MM-YYYY'))}
              place={'right'}
              className="tooltip-inner"
            />
            {score >= 40 ? (
              <P className="p16 mb-0">
                <FormattedMessage {...messages.retakeTestBetterScore} />
              </P>
            ) : (
              <P className="p16 mb-0">
                <FormattedMessage {...messages.minmumScoreMsg} />
                {moment(dateToNextAttempt).format('DD-MM-YYYY')}.
              </P>
            )}
          </>
        )}
        {!hasCompletedTest && (
          <P className="p16 mb-0">
            <FormattedMessage {...messages.testIntruction} />
          </P>
        )}
      </ListWithIcon>
    </ModalWrapper>
  );
};

export default TestModal;
