import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row, Col } from 'reactstrap';
import { P, FormLabel, Selects, A } from 'components';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import {
  companyTypeArray,
  preferredProjectTimeArray,
  teamPreferenceArray,
  assignmentArray,
  workPreferenceArray,
  roles,
  TALENT,
} from 'containers/App/constants';
import { renderSelectTags } from 'utils/Fields';
import containerMessage from 'containers/messages';
import { getFieldValidator } from './fields';
import messages from './messages';

export class PreferenceComponent extends React.Component {
  constructor(props) {
    super(props);
    const talentRoles = localStorage.getItem('talentRoles');
    this.state = {
      talentRoles: talentRoles ? JSON.parse(talentRoles) : roles?.map((role) => ({ label: role.name, value: role.name })),
    };
  }

  handleSelectChangeTags = (selectedValues = [], callback) => {
    if (callback) {
      const selectedItems = selectedValues.map((i) => i.value);
      callback(selectedItems);
    }
  };

  render() {
    const {
      industries,
      companyCultures,
      companyType,
      preferredProjectDuration = [],
      teamPreference = [],
      assignments,
      workPreference = [],
      industryList = [],
      companyCulturesList = [],
      desiredRole = '',
      onChangeIndustry,
      onChangeCompanyCultures,
      onCompanyTypeChange,
      onPreferredProjectChange,
      onTeamPreferenceChange,
      onAssignmentChange,
      onWorkPreferenceChange,
      onChangeDesiredRole,
      showSkip = true,
    } = this.props;
    const { talentRoles } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col md="6">
            <FormGroup>
              <FormLabel className="d-inline-flex align-items-center">
                <FormattedMessage {...messages.labelIndustry} />
                <P className="p14 ms-1 mb-0" opacityVal="0.5">
                  <FormattedMessage {...messages.text3MaxSelection} />
                </P>
              </FormLabel>
              <Field
                name="industries"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={industries}
                onChange={(e) => this.handleSelectChangeTags(e, onChangeIndustry)}
                options={industryList.map((item) => ({
                  label: item,
                  value: item,
                }))}
                optionLimit="3"
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel className="d-inline-flex align-items-center">
                <FormattedMessage {...messages.labelcompanyCulture} />
                <P className="p14 ms-1 mb-0" opacityVal="0.5">
                  <FormattedMessage {...messages.text3MaxSelection} />
                </P>
              </FormLabel>
              <Field
                name="companyCultures"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={companyCultures}
                optionLimit="3"
                onChange={(e) => this.handleSelectChangeTags(e, onChangeCompanyCultures)}
                options={companyCulturesList.map((item) => ({
                  label: item,
                  value: item,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <FormLabel>
                <FormattedMessage {...containerMessage.labelCompanySize} />
              </FormLabel>
              <Field
                name="companyType"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={companyType}
                onChange={(e) => this.handleSelectChangeTags(e, onCompanyTypeChange)}
                options={companyTypeArray.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel>
                <FormattedMessage {...containerMessage.labelProjectDuration} />
              </FormLabel>
              <Field
                name="preferredProjectDuration"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={preferredProjectDuration}
                onChange={(e) => this.handleSelectChangeTags(e, onPreferredProjectChange)}
                options={preferredProjectTimeArray.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <FormLabel>
                <FormattedMessage {...containerMessage.subHeadingTeam} />
              </FormLabel>
              <Field
                name="teamPreference"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={teamPreference}
                onChange={(e) => this.handleSelectChangeTags(e, onTeamPreferenceChange)}
                options={teamPreferenceArray.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel>
                <FormattedMessage {...containerMessage.subHeadingAssignment} />
              </FormLabel>
              <Field
                name="assignments"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={assignments}
                onChange={(e) => this.handleSelectChangeTags(e, onAssignmentChange)}
                options={assignmentArray.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <FormLabel>
                <FormattedMessage {...containerMessage.labelWorkSchedule} />
              </FormLabel>
              <Field
                name="workPreference"
                component={renderSelectTags}
                placeHolder={containerMessage.SelectPlaceHolder.defaultMessage}
                value={workPreference}
                onChange={(e) => this.handleSelectChangeTags(e, onWorkPreferenceChange)}
                options={workPreferenceArray.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                validate={getFieldValidator('multiSelectField', true)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel className="d-flex align-items-center">
                <FormattedMessage {...messages.labelDesiredRole} />
                {showSkip && (
                  <P className="p14 m-0 ms-1 " opacityVal="0.5">
                    (Helps our AI model to define your{' '}
                    <A href={`${TALENT}/career-plan`} className="sm text-decoration-underline">
                      career path
                    </A>
                    )
                  </P>
                )}
              </FormLabel>
              <Field
                name="desiredRole"
                type="text"
                component={Selects}
                defaultValue={desiredRole}
                searchable
                options={talentRoles}
                onChange={(e) => onChangeDesiredRole(e)}
                placeHolder={messages.placeHolderSelectDesiredRole.defaultMessage}
              />
            </FormGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

PreferenceComponent.defaultProps = {
  size: '',
};
PreferenceComponent.propTypes = {
  size: PropTypes.string,
  workPreference: PropTypes.any,
  companyCultures: PropTypes.any,
  industries: PropTypes.any,
  teamPreference: PropTypes.any,
  assignments: PropTypes.any,
  companyType: PropTypes.any,
  preferredProjectDuration: PropTypes.any,
  onChangeBrief: PropTypes.func,
  dispatch: PropTypes.any,
  onAssignmentChange: PropTypes.func,
  onTeamPreferenceChange: PropTypes.func,
  onCompanyTypeChange: PropTypes.func,
  onWorkPreferenceChange: PropTypes.func,
  onPreferredProjectChange: PropTypes.func,
  onChangeIndustry: PropTypes.func,
  onChangeCompanyCultures: PropTypes.func,
  industryList: PropTypes.any,
  companyCulturesList: PropTypes.any,
};
