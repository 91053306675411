import React from 'react';
import { errorIcon, warningIcon, infoIcon, circleTickIcon, API_URL, TALENT, START_TEST } from 'containers/App/constants';
import messages from './messages';
import { get } from 'lodash';
import { triggerAnalyticsEvent } from 'containers/App/utils';
import request from 'utils/request';
import { toast } from 'react-toastify';
import { ToastifyMessage } from 'components';
import { VALIDATION } from 'utils/constants';

export const getIconBasedOnScore = (score) => {
  let output = infoIcon;
  if (score >= 60) {
    output = circleTickIcon;
  } else if (score >= 40) {
    output = warningIcon;
  } else if (score < 40) {
    output = errorIcon;
  }
  return output;
};

export const getToolTipContentBasedOnScore = (score, date = '') => {
  let output = '';
  if (score >= 60) {
    output = messages.tooltipSuccess.defaultMessage;
  } else if (score >= 40) {
    output = messages.tooltipWarning.defaultMessage;
  } else if (score < 40) {
    output = `${messages.tooltipError.defaultMessage}${date}`;
  }
  return output;
};

export const checkRetakeTestEligibility = (selectedTestData) => {
  const checkEligibility =
    get(selectedTestData, 'hasCompletedTest', null) === true &&
    get(selectedTestData, 'hasEligibleForReTest', null) === true &&
    get(selectedTestData, 'daysRemainingToNextAttempt', null) === 0;
  return checkEligibility;
};

export const submitStartTest = (url, { selectedTestData, setLoading, onSubmitSuccess }) => {
  setLoading?.(true);
  const apiCallData = {
    method: 'POST',
    body: { url },
  };
  triggerAnalyticsEvent(
    {
      labelData: `${selectedTestData.name}`,
      actionName: 'Start',
      actionUser: 'Skill Test',
    },
    selectedTestData,
  );
  const requestURL = `${API_URL}${TALENT}${START_TEST}`;
  request(requestURL, apiCallData)
    .then(onSubmitSuccess)
    .catch((err) => {
      toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
    })
    .finally(() => {
      setLoading?.(false);
    });
};
