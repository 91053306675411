/* * SkillsList * */

import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getUserType } from 'utils/Helper';
import validateMessages from 'containers/Talent/ValidateSkills/messages';
import { editNoteIcon, infoIcon } from 'containers/App/constants';
import { Badge, P, ProgressMod } from 'components';
import { getIconBasedOnScore, getToolTipContentBasedOnScore } from 'containers/Talent/ValidateSkills/utils';
import { SkillListing } from './styles';
import { isEditAllowed } from 'containers/MyProfilePage/components/utils';
import { ActionIconLink } from 'containers/MyProfilePage/styles';
import { redirectPageURL } from 'containers/App/utils';

export const getTooltip = ({ id, content }) => (
  <Tooltip className={`tooltip-inner`} style={{ textAlign: 'left' }} place="top" id={id} content={content} />
);

export const ReactTooltip = ({ wrapperClass, title, type, titleClass, content, tooltipId, placement, otherIcon, data }) => (
  <>
    <div className={`${wrapperClass}`}>
      <div data-tooltip-id={tooltipId} className={titleClass}>
        {title}
        {type === 'info' ? <SVG src={infoIcon} /> : <SVG src={getTooltipIcon(otherIcon, data)} opacity={getOpacityVal(data)} />}
      </div>
      <Tooltip id={tooltipId} content={content} place={placement} className={`tooltip-inner`} style={{ textAlign: 'left' }} />
    </div>
  </>
);

const getTooltipIcon = (icon, data) => {
  const currentRole = getUserType();
  if (
    !data?.hasAvailableTest &&
    (currentRole === 'admin' || currentRole === 'talent' || currentRole === 'client' || currentRole === 'agency')
  ) {
    return infoIcon;
  }
  return icon;
};

const getOpacityVal = (data) => {
  const currentRole = getUserType();
  if (
    !data?.hasAvailableTest &&
    (currentRole === 'admin' || currentRole === 'talent' || currentRole === 'client' || currentRole === 'agency')
  ) {
    return '0.5';
  }
  return '1';
};

const getTooltipContentVal = (data) => {
  const currentRole = getUserType();
  if (
    !data?.hasAvailableTest &&
    (currentRole === 'admin' || currentRole === 'talent' || currentRole === 'client' || currentRole === 'agency')
  ) {
    return 'Test for this skill is not yet available on our platform';
  } else if (data?.hasCompletedTest) {
    return getToolTipContentBasedOnScore(data?.score);
  }
  return validateMessages.tooltipDefault.defaultMessage;
};

function sortArray(array) {
  array.sort((a, b) => {
    // Determine the priority group of each object
    const getPriority = (obj) => {
      if (obj.score >= 60) {
        return 1;
      }
      if (obj.score >= 40) {
        return 2;
      }
      if (!obj.score && obj.hasAvailableTest) {
        return 3;
      }
      if (!obj.score && !obj.hasAvailableTest) {
        return 4;
      }
      return 5;
    };

    const priorityA = getPriority(a);
    const priorityB = getPriority(b);

    // Compare priorities first
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // If priorities are the same, sort by rate
    if (a.rate !== b.rate) {
      return b.rate - a.rate;
    }

    // If rate is also the same, sort by name alphabetically
    return a.name.localeCompare(b.name);
  });
}

function SkillsList(props) {
  const { userSkills, isSoftSkill, userData, role } = props;

  const newSkills = (userSkills || []).slice(0, 10);
  sortArray(newSkills);
  const renderSkillText = (s) => {
    const years = s?.years;
    const months = s?.months;
    if (years) {
      return `${years > 25 ? 25 : years}+ years`;
    } else if (months) {
      return `${months}+ months`;
    }
    return userData && isEditAllowed(userData, role) ? (
      <ActionIconLink className="profile-edit" to={{ pathname: redirectPageURL(5) }}>
        <SVG src={editNoteIcon} />
      </ActionIconLink>
    ) : (
      'NA'
    );
  };

  const showYearsColumn = !isSoftSkill && (role !== '2' || (role === '2' && newSkills?.some((item) => item.years)));

  return (
    <SkillListing data-testid="SkillListing" showAllColumns={!isSoftSkill && showYearsColumn}>
      {newSkills.map((s, index) => (
        <li key={s?.name + index}>
          <div className="d-flex align-items-center">
            {isSoftSkill ? (
              ''
            ) : (
              <ReactTooltip
                wrapperClass="me-2"
                placement="right"
                type="other"
                data={s}
                // eslint-disable-next-line no-underscore-dangle
                tooltipId={`Tooltip_${s?._id}`}
                otherIcon={s?.hasCompletedTest ? getIconBasedOnScore(s?.score) : infoIcon}
                content={getTooltipContentVal(s)}
              />
            )}
            <Badge className="badge-sm primary fit-content">{s?.name}</Badge>
          </div>
          {!isSoftSkill && (
            <div className={`${showYearsColumn ? 'd-flex' : 'd-none'} align-items-center justify-content-center`}>
              <P className="p14 mb-0 text-align-center">{renderSkillText(s)}</P>
            </div>
          )}
          <ProgressMod value={s?.rate} max={10} className="sm ms-auto" />
        </li>
      ))}
    </SkillListing>
  );
}

SkillsList.propTypes = {
  userSkills: PropTypes.array,
  isSoftSkill: PropTypes.bool,
};

export default SkillsList;
