import React, { useCallback, useEffect, useState } from 'react';
import { ReactFlow } from '@xyflow/react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { ReactFlowContainer, CustomCardContainer } from './styled.js';
import EamptyScreen from 'components/EmptyScreens/EmptyScreenCard';
import { companyTypeArray, COURSES, locationIcon, TALENT, talentEmptyIcon, targetMapIcon } from 'containers/App/constants';
import { LinkMod } from 'components/A';
import messages from './messages';
import Content from 'components/Content';
import { P, PrivateGrid } from 'components';
import {
  currentRoleDetails,
  CustomCardNode,
  CustomNode,
  desiredRoleCardData,
  fetchNodeData,
  getLabels,
  getNode,
  showNodeTitle,
  targetRoleCardData,
  updateTalentDesiredRole,
} from './utils';
import '@xyflow/react/dist/style.css';
import { primaryNew, white } from 'themes/variables.js';
import CustomControl from './customControls.js';
import LoaderScreen from './loaderScreen.js';
import { ContactModal } from 'containers/Auth/SignUp/signup-styles.js';
import Preferences from 'containers/Auth/Preferences/index.js';
import TestModal from '../ValidateSkills/testModal.js';
import { get } from 'lodash';
import { submitStartTest } from '../ValidateSkills/utils.js';
import history from 'utils/history';

const nodeTypes = { customNode: CustomNode };

const TalentCareerPlan = () => {
  const storedUserDetails = localStorage.getItem('userCompleteDetails');
  const currentUserData = storedUserDetails && storedUserDetails !== 'null' && JSON.parse(storedUserDetails);
  const [baseNode, setBaseNode] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [careerPlanData, setCareerPlanData] = useState(null);
  const [targetRoleDetails, setTargetRoleDetails] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [targetRole, setTargetRole] = useState(null);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [firstCall, setFirstCall] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const payload = {
      desiredRole: targetRole || '',
    };
    if (location?.extra?.rePlan) {
      payload.rePlan = true;
      const newLocationState = { ...location, extra: { ...location.extra } };
      delete newLocationState.extra.rePlan;
      history.replace({ ...location, state: newLocationState });
    }

    if (!targetRole) {
      setSelectedNode(null);
    }
    if (firstCall && currentUserData?.data?.careerPlan && !payload.rePlan) {
      setCareerPlanData(currentUserData?.data?.careerPlan);
      setFirstCall(false);
    } else if (currentUserData?.data?.signupStep > 5) {
      fetchNodeData({ setCareerPlanData, setIsLoading, payload });
      setFirstCall(false);
    }
  }, [targetRole]);

  useEffect(() => {
    if (currentUserData && !baseNode) {
      setBaseNode(
        getNode(
          {
            experienceRequired: currentUserData?.data?.yearsOfExperience,
            careerName: currentUserData?.data?.primaryRole,
            coordinates: [0, 0],
            nodeTitle: showNodeTitle({ icon: locationIcon, title: 'You are here', type: 'primary' }),
            image: currentUserData?.data?.profilePicture,
            skills: currentUserData?.data?.skills ?? [],
          },
          -1,
        ),
      );
    }
  }, [currentUserData, baseNode]);

  const handleReplan = () => {
    const payload = {
      desiredRole: targetRole || '',
      rePlan: true,
    };
    fetchNodeData({ setCareerPlanData, setIsLoading, payload });
  };

  const getEdges = (newNodes, sourceNode = null, targetNode = null) => {
    const handles = ['top', 'middle', 'bottom'];
    return newNodes.map((node, index) => {
      const sourceId = sourceNode?.id || node?.id;
      const targetId = targetNode?.id || node?.id;
      return {
        id: `edge-${sourceId}-${targetId}`,
        source: sourceId, // Base node as the source
        target: targetId, // Connect to each response node
        type: 'straight', // You can change this to 'default' or other types
        sourceHandle: handles[index % 3],
        ...(sourceNode ? { sourceHandle: handles[index % 3] } : { targetHandle: handles[index % 3] }),
        label: `${node.data.experienceGap}+ years`,
        style: { stroke: `rgb(${primaryNew}, 0.3)`, strokeLinecap: 'round', strokeWidth: '2px' },
        labelStyle: { color: `rgb(${primaryNew})` },
        markerEnd: {
          type: 'arrowclosed',
          width: 15,
          height: 15,
          color: 'rgb(186, 194, 240)',
        },
      };
    });
  };

  useEffect(() => {
    if (careerPlanData && baseNode) {
      const newNodes = careerPlanData.paths?.map((item, index) => getNode(item, index)) || [];
      let newEdges = [...getEdges(newNodes, baseNode)];
      if (careerPlanData?.desiredRole) {
        newNodes.push(
          getNode(
            { ...careerPlanData.desiredRole, nodeTitle: showNodeTitle({ icon: targetMapIcon, title: 'Your Target' }), isSelected: true },
            newNodes.length,
          ),
        );
        setSelectedNode(careerPlanData.desiredRole);
        const companyType = getLabels(companyTypeArray, currentUserData?.data?.companyType).join(', ');
        const industry = (currentUserData?.data?.industries || []).join(', ');
        const city = currentUserData?.data?.city;
        const country = currentUserData?.data?.country;
        const location = `${city ? `${city}, ` : ''} ${country ? country : ''}`;
        const targetRoleData = { ...careerPlanData?.desiredRole, companyType, industry, location };
        setTargetRoleDetails(targetRoleData || null);
      }
      const subNodes = newNodes.slice(0, -1);
      newEdges = [...newEdges, ...getEdges(subNodes, null, newNodes.at(-1))];

      setNodes((prev) => [baseNode, ...prev, ...newNodes]);
      setEdges(newEdges);
    }
  }, [careerPlanData, baseNode]);

  const handleClick = (newDesiredRole = '') => {
    const payload = { desiredRole: newDesiredRole && typeof newDesiredRole !== 'object' ? newDesiredRole : selectedNode.careerName };
    updateTalentDesiredRole(payload);
    setTargetRole(payload?.desiredRole);
  };

  const handleRedirectToCourses = () => {
    const searchSkill = selectedNode?.skills?.find((skill = {}) => skill?.isNew || !skill?.hasOwnProperty('isNew'))?.name || '';
    let url = `${TALENT}${COURSES}`;
    if (searchSkill) {
      url += `?search=${searchSkill}`;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const showCards = () => (
    <CustomCardContainer>
      <CustomCardNode data={currentRoleDetails} userData={currentUserData?.data} />
      {targetRoleDetails && (
        <CustomCardNode
          data={targetRoleCardData({ handleClick: setShowPreferenceModal, handleBtnClick: handleRedirectToCourses })}
          userData={targetRoleDetails}
        />
      )}
      {selectedNode && targetRoleDetails && (
        <CustomCardNode
          data={desiredRoleCardData({
            handleClick,
            showBtn:
              currentUserData?.data?.primaryRole !== selectedNode?.careerName && selectedNode?.careerName !== targetRoleDetails?.careerName,
            handleSkillClick: setShowSkillModal,
          })}
          userData={targetRoleDetails}
          selectedNodeData={selectedNode}
        />
      )}
    </CustomCardContainer>
  );

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node.data);
    setNodes((prev) =>
      prev.map((n) => ({
        ...n,
        data: {
          ...n.data,
          isSelected: n.id === node.id,
        },
      })),
    );
  }, []);

  const handleModalContinueClick = (role = '') => {
    setShowPreferenceModal(false);
    handleClick(role);
  };

  const handleStartTestSubmit = (url) => {
    submitStartTest(url, {
      selectedTestData: showSkillModal,
      onSubmitSuccess: (response) => {
        if (get(response, 'status')) {
          window.open(response.data.redirectUrl, '_blank');
        }
        setShowSkillModal(false);
      },
      setLoading: setShowLoader,
    });
  };
  return (
    <>
      {!isLoading && baseNode && nodes?.length ? (
        <P className="p16 m-1 mt-0 px-md-60px p-4 py-md-0 lh-sm col-12 col-md-7" opacityVal="0.5">
          <FormattedMessage {...messages.careerGraphDescription} />
        </P>
      ) : null}
      <Content data-testid="plan-your-career-content-container">
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>

        <PrivateGrid>
          {isLoading && <LoaderScreen width={'100%'} height={'30vh'} />}
          {!isLoading && currentUserData?.data?.signupStep <= 5 && (
            <EamptyScreen
              cardDescription={messages.completeProfileDescription}
              cardTitle={messages.completeProfile}
              icon={talentEmptyIcon}
              textContainersClasses="d-flex flex-column align-items-start align-items-sm-center mt-3 mb-3 text-left"
              cardClasses="d-flex flex-column align-items-start align-items-sm-center p-40"
            >
              <LinkMod to={`${TALENT}/about-you`} className="btn btn-primary px-0 py-2 btn-sm mt-3 mt-md-0">
                <FormattedMessage {...messages.completeProfile} />
              </LinkMod>
            </EamptyScreen>
          )}
          {!isLoading && baseNode && nodes?.length ? (
            <ReactFlowContainer>
              <div className="d-flex flex-column w-100">
                <ReactFlow
                  nodes={[...nodes]}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onNodeClick={onNodeClick}
                  fitView
                  data-handle-id="top"
                  style={{ background: `rgb(${white})` }}
                  proOptions={{ hideAttribution: true }}
                  data-testid="react-flow-container"
                >
                  <CustomControl replanHandler={handleReplan} />
                </ReactFlow>
              </div>
              {showCards()}
            </ReactFlowContainer>
          ) : null}
          <ContactModal
            isOpen={showPreferenceModal}
            contentLabel="crop"
            className={`modal-dialog pricing-compare-div h-100`}
            style={{ overlay: { zIndex: 12 } }}
            shouldCloseOnOverlayClick // Allow closing by clicking outside
            onRequestClose={() => setShowPreferenceModal(false)}
            ariaHideApp={false}
            ariaModal
          >
            <div className="modal-content relative">
              <div className={`modal-body`} id="pricing-compare-div">
                <Preferences showSkip={false} continueCb={handleModalContinueClick} />
              </div>
            </div>
          </ContactModal>
          {showSkillModal && (
            <TestModal
              selectedTestData={showSkillModal}
              onDiscard={() => setShowSkillModal(false)}
              onSubmit={() => handleStartTestSubmit(get(showSkillModal, 'url', ''))}
              isLoading={showLoader}
            />
          )}
        </PrivateGrid>
      </Content>
    </>
  );
};

export default TalentCareerPlan;
