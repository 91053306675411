import styled from 'styled-components';
import { Nav } from 'reactstrap';
import { primaryDarkNew, white, primaryNew } from 'themes/variables';
import media from 'themes/media';

export const SkillTab = styled(Nav)`
  border-bottom: 0;
  margin-bottom: 20px;

  .nav-item {
    margin-right: 16px;
    margin-bottom: 16px;

    .nav-link {
      border-radius: 15px;
      padding: 6px 15px;
      border: 1px solid rgba(${primaryNew}, 0.1);
      font-size: 16px;
      line-height: 18px;
      color: rgb(${primaryDarkNew});

      &.active {
        border-color: rgb(${primaryNew});
        background: rgb(${primaryNew});
        color: rgb(${white});
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const GetVettedIntro = styled.div`
  ${media.large`
      max-width: 700px;
  `};
`;

export const TestDetails = styled.div`
  border: 1px solid rgba(${primaryNew}, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const ListWithIcon = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;

  svg {
    &.w-16 {
      width: 16px;
      height: 16px;
    }
    &.w-20 {
      width: 20px;
      height: 20px;
    }
  }
`;

export const TestIcon = styled.span`
  width: 50px;
  height: 50px;
  border: 1px solid rgba(${primaryNew}, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 20px;

  &.mb-20 {
    margin-bottom: 20px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const TestList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  ${media.medium`
    grid-template-columns: 1fr 1fr;
  `};
  ${media.large`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  .test-btn {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 90px;
    border: 1px solid rgba(${primaryNew}, 0.1);
    border-radius: 20px;
    padding: 20px;

    &.cursor-default {
      cursor: default;
    }

    .arrow-icon {
      path {
        stroke: rgba(${primaryDarkNew}, 0.5);
      }
    }

    &:disabled {
      background: initial;
      opacity: 1;
    }
    &:hover {
      border-color: rgba(${primaryNew}, 0.2);
      ${TestIcon} {
        border-color: rgba(${primaryNew}, 0.2);
      }
      .arrow-icon {
        path {
          stroke: rgba(${primaryDarkNew}, 0.7);
        }
      }
    }
    &:hover,
    &:not(:disabled):not(.disabled):active:focus {
      background: transparent;
      color: rgb(${primaryNew});
      box-shadow: none;
    }
  }
`;
