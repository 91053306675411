import styled from 'styled-components';
import { primaryDarkNew, infoGradient, white } from 'themes/variables';

export const PlanCard = styled.div`
  border: 1px solid rgba(${primaryDarkNew}, 0.1);
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  font-family: New order, sans-serif;
  height: 96%;
  box-shadow: 10px 10px 22px #04004e12;

  &.bg-info {
    border: 1px solid rgba(18, 196, 114, 1);
    background: linear-gradient(114.05deg, #fde5bf 0%, #ffceca 100%);
    box-shadow: 0px 15px 10px 0px rgba(4, 0, 78, 0.1);

    & .best-value {
      background: rgba(18, 196, 114, 1);
      top: 5px;
      right: 5px;
      border-radius: 8px;
    }
  }

  & .popular-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: ${infoGradient};
  }

  & .inner-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    justify-content: space-between;

    & button.btn-primary,
    & a.btn-outline,
    & button.btn-outline {
      max-width: 270px;
      margin: 0 auto;
      width: 100%;
    }

    & .contact-us-btn {
      background-image: linear-gradient(90deg, #05004f, #103df9);
      color: rgba(${white});
    }

    &.pt-30 {
      min-height: 137px;
      border-top: 1px solid rgba(${primaryDarkNew}, 0.1);
    }
    &.pb-30 {
      min-height: 260px;
    }
  }

  .divider {
    border-top: 1px solid rgba(${primaryDarkNew}, 0.1);
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-30 {
    margin-top: 30px;
  }
`;
