export const subscriptionPlans = [
  {
    title: 'Plus',
    description:
      'Analyse more teams, host more competitions, post more jobs and invite more dashboard users, great for companies hiring on regular basis.',
  },
  {
    title: 'Growth',
    description:
      'Supercharge your global talent sourcing with AI Co-Pilot, and automated workflows integrated with your existing tools, ideal for companies hiring continously.',
  },
  {
    title: 'Enterprise',
    description: 'Enterprise - grade data security, Custom integrations, and dedicated account managers for your global hiring needs',
    // headerClass: 'fw-semibold',
    // descriptionClass: 'fw-bold ',
  },
];
