import { A, Button, Card, H4, H5, H6, Input, P } from 'components';

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { API_URL, brandingDarkIcon, CLIENT, closeIcon, infoIcon, thumbsUpIcon, VERSION2 } from 'containers/App/constants';
import request from 'utils/request';
import Skeleton from 'react-loading-skeleton';
import TalentCardComponent from 'containers/TalentListingPage/TalentCardComponent';
import { useAppSelector } from 'hooks';
import SubscriptionModal from 'containers/SubscriptionModal';
import history from 'utils/history';
import { useSelector } from 'react-redux';
import { getRandomSuggestions } from 'containers/ClientHomePage/utils';
import { userExists } from 'utils/Helper';
import { FeedbackBotContainer, FeedbackBotChatContainer, InstructionContainer } from './styled.js';
import TypewriterMessage from '../GPTSearchBot/typeWriterContainer.js';
import { feedbackSearchSuggestions, instructionData, renderFeedbackSearchSuggestions } from './utils.js';
import { isMobileWidth } from 'utils/windowUtils.js';
import messages from 'containers/Talent/Dashboard/messages.js';
import { FormattedMessage } from 'react-intl';
import { getHash, triggerAnalyticsEvent } from 'containers/App/utils.js';

const successPath = `${CLIENT}/dashboard?success=true`;
const cancelPath = `${CLIENT}/dashboard?success=false`;

const AIHiringAgent = ({ searchText = '', onExit }) => {
  const [sessionId, setSessionId] = useState(null);
  const [isRefinement, setIsRefinement] = useState(false);
  const [message, setMessage] = useState(searchText);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleInstructionView, setToggleInstructionView] = useState(false);
  const [showSuggestionBubbles, setShowSuggestionsBubbles] = useState(false);
  const [randomSuggestions, setRandomSuggestions] = useState([]);
  const clientDetails = useAppSelector((state) => state.global.clientDetails);
  const clientBasicDetails = localStorage.getItem('clientBasicDetails') ? JSON.parse(localStorage.getItem('clientBasicDetails')) : {};
  const chatAreaRef = useRef(null);
  const subscriptionModalRef = useRef();
  const compareTalentsList = useSelector((state) => state.compareTalents?.compareTalents);
  const signedInUser = userExists();

  useEffect(() => {
    document.title = 'Kay - Your AI Co-Pilot to Build Best Engineering Teams';
    const hash = getHash(window.location, 'AI_COPILOT');
    history.replace(`${window.location.pathname}${hash}`);
    if (searchText) {
      sendText();
    } else {
      leftMessage('Hello! I am Kay, your hiring agent.');
      const suggestions = getRandomSuggestions(feedbackSearchSuggestions, 3);
      setRandomSuggestions(suggestions);
      setShowSuggestionsBubbles(true);
    }
    triggerAnalyticsEvent(
      {
        labelData: clientDetails?.name || clientBasicDetails?.name || 'Anonymous user',
        actionName: 'AI_Copilot_Visit',
        actionUser: 'Client',
        userType: 'client',
      },
      {},
    );

    const timer = setInterval(() => {
      const intercomElement = document.querySelector('.intercom-lightweight-app');
      const intercomContainer = document.querySelector('#intercom-container');

      if (intercomElement) {
        intercomElement.style.display = 'none';
      }
      if (intercomContainer) {
        intercomContainer.style.display = 'none';
      }
    }, 1000); // Check every 100ms
    const timeout = setTimeout(() => {
      clearInterval(timer);
    }, 20000);
    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    scrollToBottomSmooth();
  }, [chatMessages]);

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (e.key === 'Enter') {
        sendText();
      }
    };
    window.addEventListener('keydown', handleEnterPress);
    scrollToBottomSmooth();
    return () => {
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [message]);

  const sendText = (customText = '') => {
    const realPrompt = customText || message;
    if (!realPrompt) {
      return;
    }
    rightMessage(realPrompt);
    scrollToBottomSmooth();
    getResponse(realPrompt);
    setMessage('');
    setShowSuggestionsBubbles(false);
  };

  const getResponse = async (text = '') => {
    setIsLoading(true);
    triggerAnalyticsEvent(
      {
        labelData: clientDetails?.name || clientBasicDetails?.name || 'Anonymous user',
        actionName: 'AI_Copilot_Message',
        actionUser: 'Client',
        userType: 'client',
      },
      { searchQuery: text },
    );
    const data = {
      method: 'POST',
      body: {
        prompt: text || message,
        ...(sessionId && { threadId: sessionId }),
        ...(isRefinement && { isRefinement: true }),
      },
    };
    const requestURL = `${API_URL}${VERSION2}/bot/roadmap-bot/send`;
    request(requestURL, data)
      .then((response) => {
        if (response?.status) {
          if (response.data?.threadId) {
            setSessionId(response.data.threadId);
          }
          leftMessage(response.data?.response || '', {
            talents: response?.data?.talents || [],
            responseData: response.data,
          });
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const leftMessage = (text, { talents = [], responseData = {} } = {}, speed = 10) => {
    setChatMessages((prevMessages) => [...prevMessages, { text, talents, sender: 'app', speed, responseData }]);
    scrollToBottomSmooth();
  };

  const rightMessage = (text) => {
    setChatMessages((prevMessages) => [...prevMessages, { text, sender: 'user' }]);
    scrollToBottomSmooth();
  };

  const scrollToBottomSmooth = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight + 50;
    }
  };

  const handleInputChange = (e) => setMessage(e.target.value);

  const handleSuggestionClick = (e) => {
    setMessage(e.target.textContent);
    sendText(e.target.textContent);
    setShowSuggestionsBubbles(false);
  };

  const handleInterviewClick = (talent) => {
    const { firstName, name } = talent;
    const text = `Schedule interview with ${name || firstName}`;
    setMessage(text);
    sendText(text);
  };

  return (
    <FeedbackBotContainer className="bg-white mx-4 d-flex pt-1">
      <Row className="border-bottom p-2 d-flex align-items-center">
        <Col xs={10} md={9}>
          <div className="d-flex align-items-center logo">
            <img src={brandingDarkIcon} alt="Notchup" className="d-flex" width={40} height={40} />
          </div>
          <H5>Kay - Your AI Co-Pilot to Build Best Engineering Teams</H5>
        </Col>
        {isMobileWidth() ? (
          <>
            <Col xs={1}>
              <A href="https://form.typeform.com/to/QUXwtwjW" target="_blank" className="ps-2">
                <img src={thumbsUpIcon} alt="" width="18" height="18" />
              </A>
            </Col>
            <Col xs={1}>
              <button
                className="btn btn-sm btn-link info-btn"
                type="button"
                id={`info`}
                onClick={() => setToggleInstructionView((prev) => !prev)}
              >
                <SVG src={infoIcon} width={15} />
              </button>
            </Col>
          </>
        ) : (
          <Col md={3}>
            <Card className="p-0 d-flex justify-content-end align-items-center">
              <div className="align-self-end">
                <P className="p16 mb-0">
                  <FormattedMessage {...messages.CoPilotFeedbackTitle} />
                </P>
              </div>
              <div className="d-flex align-items-center justify-content-space-evenly justify-content-lg-center ps-2">
                <A href="https://form.typeform.com/to/QUXwtwjW" target="_blank">
                  <img src={thumbsUpIcon} alt="" width="30" height="30" />
                </A>
              </div>
            </Card>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={8} className="order-md-1 order-2">
          <FeedbackBotChatContainer>
            <div
              className="flex-grow-1 px-3 py-1 position-relative feedback-bot-chat-container"
              style={{ overflowY: 'auto', height: '80vh' }}
              ref={chatAreaRef}
            >
              {chatMessages.map((msg, index) => (
                <React.Fragment key={index}>
                  <div className={`d-flex ${msg.sender === 'user' ? 'justify-content-end' : ''} mb-2`}>
                    {msg.sender === 'app' && (
                      <img className="align-self-center me-2 icon" src={brandingDarkIcon} alt="Notchup" width={30} height={30} />
                    )}
                    <div className={`p-2 rounded shadow-sm ${msg.sender === 'user' ? 'bg-user' : 'bg-bot'}`} style={{ maxWidth: '75%' }}>
                      {msg.sender === 'app' ? <TypewriterMessage text={msg.text} showHTML /> : <p className="mb-0">{msg.text}</p>}
                    </div>
                  </div>
                  <Row>
                    {msg.talents?.map((talent) => (
                      <Col
                        lg={4}
                        md={6}
                        xl={4}
                        className="d-flex flex-column"
                        key={talent._id}
                        id={talent._id}
                        data-testid={`chat-talentcard-${talent._id}`}
                      >
                        <TalentCardComponent
                          talentData={talent}
                          clientDetails={clientDetails}
                          SubscriptionModalRef={subscriptionModalRef}
                          hideInterviewBtn={false}
                          disableSaveToList
                          disableCompareTalent
                          openNewTabForInterview
                          isPublic
                          interviewAction={() => handleInterviewClick(talent)}
                        />
                      </Col>
                    ))}
                    <div className="d-flex">
                      {index === chatMessages.length - 1 &&
                        msg.responseData?.actions?.map((action) => (
                          <Button
                            className="btn-sm btn-outline me-2"
                            // onClick={() => handleActionClick(action, msg?.responseData?.searchParams)}
                            data-testid={`btn-${action.label || ''}`}
                          >
                            {action.label}
                          </Button>
                        ))}
                    </div>
                  </Row>
                </React.Fragment>
              ))}

              {isLoading && (
                <>
                  <Skeleton height={20} width={`75%`} style={{ marginBottom: 10 }} />
                  <Skeleton height={20} width={`75%`} style={{ marginBottom: 10 }} />
                </>
              )}
              {showSuggestionBubbles &&
                renderFeedbackSearchSuggestions({
                  className: 'bottom-suggestion',
                  onClick: handleSuggestionClick,
                  suggestions: randomSuggestions,
                })}
            </div>
            <Row className="d-flex justify-content-between mt-2">
              <Col xs="9" md={10}>
                <Input
                  className="search-input"
                  type="text"
                  id="msg"
                  placeholder="Ask or search anything..."
                  value={message}
                  onChange={handleInputChange}
                  data-testid="search-input"
                />
              </Col>
              <Col xs="3" md={2}>
                <Button
                  className="w-100 btn btn-sm btn-plus btn-primary"
                  onClick={() => sendText()}
                  disabled={isLoading}
                  data-testid="send-text-btn"
                >
                  Send
                </Button>
              </Col>
            </Row>
          </FeedbackBotChatContainer>
        </Col>
        <Col md={4} className="order-md-2 order-1">
          <InstructionContainer className={`${isMobileWidth() && !toggleInstructionView ? 'd-none' : 'd-block'}`}>
            {isMobileWidth() && toggleInstructionView && (
              <button className="close-icon" onClick={() => setToggleInstructionView(false)}>
                <SVG src={closeIcon} width={10} />
              </button>
            )}
            {instructionData.map((instruction) => (
              <div key={instruction?.header}>
                <H6 className={`${instruction.headerClass || ''}`}>{instruction.header}</H6>
                <ul>
                  {instruction.instructionItems?.map((instructionItem, index) => (
                    <li className="instruction-item" key={index}>
                      <P className="p16 mb-1">{instructionItem}</P>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </InstructionContainer>
        </Col>
      </Row>
      <SubscriptionModal
        ref={subscriptionModalRef}
        history={history}
        successPath={successPath}
        cancelPath={cancelPath}
        isTalentUpgrade
        modalClassName="floating"
      />
    </FeedbackBotContainer>
  );
};

export default AIHiringAgent;
