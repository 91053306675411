/** InviteClient Page
 * This is the InviteClient in talent profile
 */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { FormGroup, Row, Col } from 'reactstrap';
import { FormLabel, Button, ToastifyMessage, P, PrivateGrid, H4, H2, Card } from 'components';
import { createStructuredSelector } from 'reselect';
import { reduxForm, Field, change, touch } from 'redux-form';
import * as normalize from 'utils/normalize';
import { API_URL, copyIcon, LIST, ROLE } from 'containers/App/constants';
import Emitter from 'utils/emitter';
import * as formValidations from 'utils/formValidations';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { VALIDATION } from 'utils/constants';
import request from 'utils/request';
import { loadRepos } from 'containers/App/actions';
import { makeSelectLoading } from 'containers/App/selectors';
import { propTypes } from 'containers/proptypes';
import Selects from 'components/Selects';
import * as actions from 'containers/Admin/AddACompanyPage/actions';
import * as selectors from 'containers/Admin/AddACompanyPage/selectors';
import reducer from 'containers/Admin/AddACompanyPage/reducer';
import saga from 'containers/Admin/AddACompanyPage/saga';
import { key } from 'containers/Admin/AddACompanyPage/constants';
import messages from './messages';
import Content from 'components/Content';
import { Helmet } from 'react-helmet-async';
import { history } from 'store/configureStore';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';
import { renderField } from 'utils/Fields';
import { redirectTo } from 'containers/App/utils';
import SVG from 'react-inlinesvg';
import { formatAURL } from 'utils/Helper';

class InviteClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      companyDetails: get(props, 'location.extra.data', {}),
    };
  }

  componentDidMount() {
    this.loadClientRoles();
  }

  loadClientRoles = () => {
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${ROLE}${LIST}?role=2`;
    request(requestURL, data)
      .then(this.setClientRoles)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setClientRoles = (response) => {
    if (get(response, 'status')) {
      this.setState({ roles: response.data.docs });

      const { dispatch, onChangeDomain } = this.props;
      const { companyDetails } = this.state;
      const data = { invitedUserEmail: '', invitedUsername: '', domain: `@${get(companyDetails, 'domain', '')}`, invitedUserRole: null };

      Object.keys(data).forEach((fieldKey) => {
        dispatch(change(key, fieldKey, data[fieldKey]));
        dispatch(touch(key, fieldKey));
      });

      onChangeDomain(data?.domain);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  handleChangeInviteRole = (value) => {
    const { dispatch, onChangeInvitedUserRole } = this.props;
    const newInviteRole = value;

    dispatch(change(key, `invitedUserRole`, newInviteRole));
    dispatch(touch(key, `invitedUserRole`));
    onChangeInvitedUserRole(newInviteRole);
  };

  handleCreateAccount = (e) => {
    const { invitedUsername, invitedUserEmail, domain, invitedUserRole, onSubmitCreateAccount } = this.props;
    const { companyDetails } = this.state;

    const data = {
      companyId: get(companyDetails, '_id', ''),
      email: invitedUserEmail.trim() + domain,
      fullName: invitedUsername,
    };

    const status = get(companyDetails, 'status');

    if (status !== 'Invited') {
      data['role'] = invitedUserRole?.value;
    }

    onSubmitCreateAccount(e, data, () => redirectTo(null, '/admin/company-listing'));
  };

  copyToclipboard = (url) => {
    navigator.clipboard.writeText(url);
    toast.success(<ToastifyMessage message="URL copied to clipboard" type="success" />, {
      className: 'Toast-success',
    });
  };

  constructURL = () => {
    const { companyDetails } = this.state;
    const origin = window.location.origin;

    if (get(companyDetails, 'brand')) {
      const url = `${origin}/${formatAURL(get(companyDetails, 'brand'))}`;
      return url;
    }

    return '';
  };

  render() {
    const {
      invalid,
      loading,
      handleSubmit,
      invitedUserEmail,
      onChangeInvitedUserEmail,
      domain,
      invitedUsername,
      onChangeInvitedUsername,
      invitedUserRole,
      btnClass,
    } = this.props;
    const { companyDetails, roles } = this.state;

    const status = get(companyDetails, 'status');

    return (
      <>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
        </Helmet>
        <Content>
          <Card>
            <PrivateGrid className="dense">
              <H2 className="modal-title d-flex align-items-center mb10">Invite</H2>
              <P className="p16 mb-6 " opacityVal="0.5">
                Invite client to claim this company profile or other users to access this profile.
              </P>

              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <>
                    <Row>
                      <Col className="col-12 d-flex align-items-center">
                        <div className="d-inline  mb-3">
                          <H4 className="newH4 m-0 d-inline" opacityVal="0.5">
                            Invite by email
                          </H4>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <Row>
                          <Col md={7} className="d-flex">
                            <Col md={7} className="ps-0">
                              <FormGroup>
                                <FormLabel>Email</FormLabel>
                                <Field
                                  name="invitedUserEmail"
                                  component={renderField}
                                  type="text"
                                  defaultValue={invitedUserEmail}
                                  placeholder="Enter work email"
                                  onChange={onChangeInvitedUserEmail}
                                  normalize={normalize.trimSpace}
                                  validate={[formValidations.required]}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={5} className="ms-1">
                              <FormGroup>
                                <FormLabel></FormLabel>
                                <Field
                                  name="domain"
                                  readOnly
                                  className="opacity-25"
                                  component={renderField}
                                  type="text"
                                  defaultValue={domain}
                                  validate={[formValidations.required]}
                                />
                              </FormGroup>
                            </Col>
                          </Col>
                          <Col md={5}>
                            <FormGroup>
                              <FormLabel>Full name</FormLabel>
                              <Field
                                name="invitedUsername"
                                component={renderField}
                                type="text"
                                defaultValue={invitedUsername}
                                placeholder="e.g. John Doe"
                                onChange={onChangeInvitedUsername}
                                validate={[
                                  formValidations.minLength2,
                                  formValidations.maxLength30,
                                  formValidations.requiredField,
                                  formValidations.isHTMLtagContent,
                                  formValidations.isValidNameFormate,
                                ]}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {status !== 'Invited' && (
                      <Row>
                        <Col>
                          <FormGroup className="input-sm left-border mb-0">
                            <FormLabel>Role</FormLabel>
                            <Field
                              name={'invitedUserRole'}
                              type="text"
                              component={Selects}
                              value={invitedUserRole}
                              options={roles.map((trole) => ({
                                label: trole.name,
                                value: trole._id,
                              }))}
                              placeHolder="Select"
                              onChange={(e) => this.handleChangeInviteRole(e)}
                              searchable
                              validate={[formValidations.requiredSelect]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <Row className="d-none align-items-center">
                      <Col md={7} lg={8}>
                        <FormGroup>
                          <FormLabel>Profile link</FormLabel>
                          {renderField({
                            label: 'Profile link',
                            type: 'text',
                            meta: { touched: false, error: false, warning: false, active: false },
                            readOnly: true,
                            disabled: true,
                            value: this.constructURL(),
                          })}
                        </FormGroup>
                      </Col>

                      <Col md={5} lg={4}>
                        <Button
                          className="btn btn-outline btn-icon text-primary text-center d-flex justify-content-center w-100"
                          type="button"
                          onClick={() => this.copyToclipboard(this.constructURL())}
                        >
                          Copy profile link
                          <SVG src={copyIcon} className="ms-2" />
                        </Button>
                      </Col>
                    </Row>
                  </>
                </div>

                <div className={`${'d-flex justify-content-between flex-column flex-md-row w-100 mt-4'}`}>
                  <Button className="btn btn-outline btn-sm mt-2" type="button" onClick={() => redirectTo(null, '/admin/company-listing')}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    className={`${btnClass ? 'loading' : ''} ${getBtnClass(loading)} btn-sm mt-2`}
                    disabled={invalid}
                    onClick={this.handleCreateAccount}
                  >
                    Send Invite
                  </Button>
                </div>
              </form>
            </PrivateGrid>
          </Card>
        </Content>
      </>
    );
  }
}

InviteClient.propTypes = propTypes;

const mapStateToProps = createStructuredSelector({
  invitedUserEmail: selectors.makeInvitedUserEmail(),
  invitedUsername: selectors.makeInvitedUsername(),
  invitedUserRole: selectors.makeInvitedUserRole(),
  domain: selectors.makeDomain(),
  loading: makeSelectLoading(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onChangeInvitedUsername: (evt) => dispatch(actions.onChangeInvitedUsername(evt.target.value)),
    onChangeInvitedUserEmail: (evt) => dispatch(actions.onChangeInvitedUserEmail(evt.target.value)),
    onChangeInvitedUserRole: (value) => dispatch(actions.onChangeInvitedUserRole(value)),
    onChangeDomain: (value) => dispatch(actions.onChangeDomain(value)),
    onSubmitCreateAccount: (evt, data, onSuccess) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(actions.submitCreateAccount(data, onSuccess));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    touchOnChange: true,
  }),
)(InviteClient);
