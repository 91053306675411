import React from 'react';
import Emitter from 'utils/emitter';
import StorageService from 'utils/StorageService';
import TalentHeader from 'components/Header/TalentHeader';
import ClientHeader from 'components/Header/ClientHeader';
import RecruiterHeader from 'components/Header/RecruiterHeader';
import AmbassadorHeader from 'components/Header/AmbassadorHeader';
import { getUserType } from 'utils/Helper';
import { arrowRightIcon, brandingDarkIcon, CLIENT } from 'containers/App/constants';
import Autocomplete from 'containers/TalentListingPage/AutoComplete';
import { fetchRoles, getSkills } from 'containers/Auth/utils';
import get from 'lodash/get';
import ToastifyMessage from 'components/ToastifyMessage';
import { toast } from 'react-toastify';
import { talentOnboardingSteps, clientOnboardingSteps, agencyOnboardingSteps } from 'containers/Auth/constants';
import { redirectTo } from 'containers/App/utils';
import history from 'utils/history';
import PageTitle from './PageTitle';
import { HeaderNav, FixedNavbar, NavbarContainer } from './header-style';
import { PrimaryDarkSVG, SubscriptionBanner } from 'containers/ClientHomePage/styles';
import P from 'components/P';
import SubscriptionModal from 'containers/SubscriptionModal';
import { cancelPath, successPath } from 'containers/ClientHomePage/utils';

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props?.openMenu,
      isSearchbarOpen: false,
      skillsArray: [],
      skillsList: [],
      WindowSize: window.innerWidth,
    };
    this.SubscriptionModalRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    this.setState({ WindowSize: window.innerWidth });
  }

  componentDidMount() {
    const currentRole = getUserType();
    if (currentRole && !['client', 'admin'].includes(currentRole) && !localStorage.getItem('talentRoles')) {
      getSkills(this.setSkills);
      fetchRoles({ role: 1 });
    }
    window.addEventListener('resize', () => this.handleResize());

    Emitter.on(Emitter.EVENTS.HAMBURGER_TOGGLE, (isOpen) => {
      this.setState({ isOpen });
    });

    Emitter.on(Emitter.EVENTS.SEARCHBAR_OPEN, (isSearchbarOpen) => {
      this.setState({ isSearchbarOpen });
    });
  }

  setSkills = (response) => {
    if (get(response, 'status')) {
      this.setState({ skillsList: response.data });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  componentWillUnmount() {
    window.addEventListener('resize', null);
    Emitter.off(Emitter.EVENTS.HAMBURGER_TOGGLE);
    Emitter.off(Emitter.EVENTS.SEARCHBAR_OPEN);
  }

  searchSkillsValueChangedTemp = (data, clearFilter) => {
    const { value } = data;
    const filterObjectGet = localStorage.getItem('filterObject');
    let filterObject;
    try {
      filterObject = JSON.parse(filterObjectGet) || { skillsArray: [] };
    } catch {
      filterObject = { skillsArray: [] };
    }
    const skillsArray = filterObject.skillsArray || [];
    const newSkillsArray = clearFilter ? skillsArray.filter((i) => i.value !== value) : [value].concat(skillsArray);
    this.storeSelectedFilterValue(
      'skillsArray',
      newSkillsArray.map((val) => val),
    );
    this.setReservedFilter(newSkillsArray.map((val) => val));
    if (!(history && history.location && history.location.pathname === `${CLIENT}/listings`)) {
      redirectTo(history, `${CLIENT}/listings`);
    }
  };

  setReservedFilter = (skillsArray) => {
    this.setState({ skillsArray });
  };

  storeSelectedFilterValue = (keyName, KeyValue) => {
    const filterObjectGet = localStorage.getItem('filterObject');
    const filterObject = JSON.parse(filterObjectGet) || {};
    filterObject[keyName] = KeyValue;
    localStorage.setItem('filterObject', JSON.stringify(filterObject));
    Emitter.emit('filterObject');
  };

  renderHeader = (currentRole) => {
    const { WindowSize } = this.state;
    let output = '';
    if (currentRole === 'talent' || currentRole === 'agency') {
      output = <TalentHeader {...this.props} windowSize={WindowSize} />;
    }
    if (currentRole === 'client' || currentRole === 'admin') {
      output = <ClientHeader {...this.props} windowSize={WindowSize} />;
    }
    if (currentRole === 'talent-partner') {
      output = <RecruiterHeader {...this.props} />;
    }
    if (currentRole === 'ambassador') {
      output = <AmbassadorHeader {...this.props} />;
    }
    return output;
  };

  render() {
    const { isOpen, isSearchbarOpen, skillsList, skillsArray, WindowSize } = this.state;
    const currentRole = getUserType();

    const isOnbardingSteps =
      talentOnboardingSteps.map((ele) => ele.url).includes(history.location.pathname) ||
      clientOnboardingSteps.map((ele) => ele.url).includes(history.location.pathname) ||
      agencyOnboardingSteps.map((ele) => ele.url).includes(history.location.pathname);

    const classNameGenerator = () => {
      let styleClass = '';
      if (isOnbardingSteps || WindowSize <= 768) {
        styleClass = 'fixed-nav';
      } else {
        styleClass = 'absolute-nav';
      }
      return styleClass;
    };

    const isSubscribed = Number(StorageService.get('subscribed'));
    const restrictedActions = Number(StorageService.get('restrictedActions'));
    const isSubscribedClientUser = isSubscribed === 0;

    return (
      <>
        <HeaderNav id="HeaderNav">
          <FixedNavbar className={`${isOpen ? 'sidebar-open' : 'sidebar-close'} ${classNameGenerator()}`} id="FixedNavbar">
            <NavbarContainer fluid id="NavbarContainer">
              <div className="d-flex align-items-center logo">
                <img src={brandingDarkIcon} alt="Notchup" className="d-flex d-md-none" width={40} height={40} />
                <PageTitle windowSize={WindowSize} currentRole={currentRole} id="PageTitle" />
                {currentRole === 'client' && isSubscribedClientUser ? (
                  <SubscriptionBanner
                    style={{
                      cursor: 'pointer',
                    }}
                    className="mb-0"
                    background={`#ECB80033`}
                    onClick={() => this.SubscriptionModalRef.current.handleSubscriptionPlanOpenModal()}
                  >
                    <P className="p16 mb-0">Upgrade to Plus</P>
                    <PrimaryDarkSVG src={arrowRightIcon} width="16" height="16" className="ms-2" />
                  </SubscriptionBanner>
                ) : (
                  ''
                )}
                {currentRole === 'client' &&
                restrictedActions &&
                restrictedActions.includes('ADD_JOB') &&
                restrictedActions.includes('ADD_TALENT') &&
                restrictedActions.includes('ADD_PROJECT') ? (
                  <SubscriptionBanner
                    style={{
                      cursor: 'pointer',
                    }}
                    className="mb-0"
                    background={`#ECB80033`}
                    onClick={() => this.SubscriptionModalRef.current.handleSubscriptionPlanOpenModal()}
                  >
                    <P className="p16 mb-0">Upgrade to Enterprise</P>
                    <PrimaryDarkSVG src={arrowRightIcon} width="16" height="16" className="ms-2" />
                  </SubscriptionBanner>
                ) : (
                  ''
                )}
              </div>
              {currentRole === 'client' && isSearchbarOpen && (
                <div className="search d-md-none">
                  <Autocomplete
                    customClass="skill-search order-lg-0 order-1"
                    options={skillsList.filter((v) => skillsArray.filter((i) => i.value === v).length === 0)}
                    label=""
                    filterType="skillSearch"
                    placeholder="Search by skills"
                    valueChanged={this.searchSkillsValueChangedTemp}
                  />
                </div>
              )}
              {this.renderHeader(currentRole)}
            </NavbarContainer>
          </FixedNavbar>
        </HeaderNav>
        <SubscriptionModal
          ref={this.SubscriptionModalRef}
          isNormalUpgrade
          history={history}
          successPath={successPath}
          cancelPath={cancelPath}
          modalClassName="floating subscription-modal"
          modalContentClass="w-960"
        />
      </>
    );
  }
}

export default Header;
