/*
 * ProfessionalDetails Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.Preferences';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'Your work preferences',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'Your work preferences',
  },
  headingPreferences: {
    id: `${scope}.headingPreferences`,
    defaultMessage: 'Your work preferences',
  },
  preferencesTagLine: {
    id: `${scope}.preferencesTagLine`,
    defaultMessage:
      'What kind of working style suits you best? Please select your preferred project type, company and work setting. This helps us better match you with the most relevant employment opportunities.',
  },

  labelIndividual: {
    id: `${scope}.labelIndividual`,
    defaultMessage: 'Individuals',
  },
  labelSmallTeam: {
    id: `${scope}.labelSmallTeam`,
    defaultMessage: 'Small team (<6)',
  },
  labelLargeTeam: {
    id: `${scope}.labelLargeTeam`,
    defaultMessage: 'Large Team (7 - 12)',
  },
  labelXLargeTeam: {
    id: `${scope}.labelXLargeTeam`,
    defaultMessage: 'X-Large Team (12+)',
  },
  labelRemoteOnly: {
    id: `${scope}.labelRemoteOnly`,
    defaultMessage: 'Remote Only',
  },
  labelOccassionalVisit: {
    id: `${scope}.labelOccassionalVisit`,
    defaultMessage: 'Occasional Site Visit',
  },
  labelShortTerm: {
    id: `${scope}.labelShortTerm`,
    defaultMessage: 'Short-term Onsite (< 3 months)',
  },
  labelMidTerm: {
    id: `${scope}.labelMidTerm`,
    defaultMessage: 'Mid-term Onsite (3 - 6 months)',
  },
  labelLongTerm: {
    id: `${scope}.labelLongTerm`,
    defaultMessage: 'Long-term Onsite (6 - 12 months)',
  },
});
