import React from 'react';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import tzlookup from 'tz-lookup';
import StorageService from 'utils/StorageService';
import includes from 'lodash/includes';
import { languageData, currencyData, roles, roleYears, yearsOfExperienceLable, GITHUB_CLIENT_ID } from 'containers/App/constants';
import { VALIDATION, MAX_FILE_SIZE } from 'utils/constants';
import { getSelectedFieldFromList } from 'containers/Auth/PersonalDetails/utils';
import { isEmpty } from 'lodash';
import { userExists } from 'utils/Helper';
import { ToastifyMessage } from 'components';
import messages from '../messages';

export const getArrayLabels = (values, list) => (values || []).map((v) => (list?.find((l) => l.value === v) || {}).label);
export const getLabel = (value, list, key) => (list.find((p) => p.value === value) || {})[key || 'label'];

export const getTimezoneOffest = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).offset;
export const getTimezoneCountry = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).countryName;
export const getCurrencySymbol = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).symbol_native;
export const getLanguageLabel = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).language;
export const getDISCProfileLabel = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).title;
export const getCompanyTypeLabel = (list, listCompareKey, value) => (list.find((c) => c[listCompareKey] === value) || {}).label;

export const getTimeZonefromLatLong = (lat, lng) => tzlookup(lat, lng);

export const checkIfFileSize = (file) => {
  let output = 0;
  const fileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  if (file.size / 1024 < 5) {
    output = VALIDATION.invalidFile;
  } else if (file.size > MAX_FILE_SIZE) {
    output = VALIDATION.invalidFile;
  } else if (!includes(fileTypes, file.type)) {
    output = VALIDATION.invalidFileType;
  }
  return output;
};

export const languageLabel = (item) => {
  let output = getLanguageLabel(languageData, 'code', item?.name || '');
  output = output || item?.name || '';
  return output;
};

export const discProfileLabel = (name) => {
  let output = getDISCProfileLabel(
    [
      {
        code: 'Dominance',
        title: 'Dominance (D)',
      },
      {
        code: 'Influence',
        title: 'Influence (I)',
      },
      {
        code: 'Steadiness',
        title: 'Steadiness (S)',
      },
      {
        code: 'Conscientiousness',
        title: 'Conscientiousness (C)',
      },
    ],
    'code',
    name,
  );
  output = output || name;
  return output;
};

export const handleLinkClick = (link, message = messages.talentLinkCopiedToClipBoard.defaultMessage) => {
  navigator.clipboard.writeText(link);
  toast.success(<ToastifyMessage message={message} type="success" />, {
    className: 'Toast-success',
  });
};

export const getRateBasedOnEmpType = (currencyDataObject) => {
  let output = '';
  const { employmentType, currency, annualRate, ratePerHour, currencyAnnualRate } = currencyDataObject;

  if (employmentType.length > 0) {
    if (employmentType.includes('freelancer-consultant')) {
      const currencySymbol = getCurrencySymbol(currencyData, 'code', currency);
      output = `${currencySymbol || ''}${ratePerHour}`;
    }
    if (employmentType.includes('permanent-employee')) {
      const currencySymbol = getCurrencySymbol(currencyData, 'code', currencyAnnualRate);
      output = `${currencySymbol || ''}${annualRate}`;
    }
  } else {
    const currencySymbol = getCurrencySymbol(currencyData, 'code', currency);
    output = `${currencySymbol || ''}${ratePerHour}`;
  }
  return output;
};

export const getMetaData = (user) => {
  const meta = {
    title: messages.metaTitle.defaultMessage,
    description: messages.metaTitle.defaultMessage,
    metaKeyWord: '',
    image: '',
  };

  if (user) {
    const primaryRoleValue = get(user, 'primaryRole') ? getSelectedFieldFromList(roles, 'name', user.primaryRole) : '';
    const primaryRole = primaryRoleValue ? primaryRoleValue.name : '';

    const yearsOfExperienceValue = get(user, 'yearsOfExperience')
      ? getSelectedFieldFromList(roleYears, 'name', user.yearsOfExperience)
      : '';
    const yearsOfExperience = yearsOfExperienceValue ? yearsOfExperienceValue.name : '';

    const userSeniority = get(user, 'yearsOfExperience');
    const totalYearsOfExperience = isEmpty(userSeniority) ? '' : yearsOfExperienceLable[userSeniority.trim()];

    const userData = {
      firstName: get(user, 'firstName'),
      lastName: get(user, 'lastName'),
      phoneNumber: get(user, 'phoneNumber'),
      addressLineOne: get(user, 'addressLineOne'),
      addressLineTwo: get(user, 'addressLineTwo'),
      city: get(user, 'city'),
      postcode: get(user, 'postcode'),
      country: get(user, 'country'),
      primaryRole,
      yearsOfExperience,
      gender: get(user, 'gender'),
      skills: get(user, 'skills', []),
    };
    const updatedLastName = userData.lastName ? `${userData.lastName.charAt(0)}.` : '';
    const skills = userData.skills.reduce((acc, skill) => {
      acc.push(skill.name);
      return acc;
    }, []);
    const metaDesc2 = `${userData.gender === 'Male' ? 'He' : 'She'} is skilled in ${skills.join(', ')}`;
    const metaKeyWord = `${userData.firstName},${userData.lastName},${userData.primaryRole},${skills.join(
      ', ',
    )}, hire, remote, web3, talent, notchup, team.`;
    meta.title = `Hire ${userData.firstName} ${updatedLastName} - ${userSeniority} ${userData.primaryRole}, ${userData.city}, ${userData.country} on Notchup.`;

    meta.description = `${userData.firstName} ${updatedLastName} ${messages.metaDescription.defaultMessage}. ${metaDesc2} and brings over ${totalYearsOfExperience} years of professional experience.`;
    meta.metaKeyWord = metaKeyWord;
    meta.image = get(user, 'profilePicture') ? `${get(user, 'profilePicture', '')}?_t=${new Date().getTime()}` : '';
  }
  return meta;
};

export const getMetaDataForRefferal = (user) => {
  const meta = {
    title: '',
    description: `Join our community of vetted tech talent like ${get(
      user,
      'firstName',
      '',
    )} and get hired by fast growing tech companies hiring exclusively on Notchup.`,
    metaKeyWord: '',
    image: '',
  };

  if (user) {
    const primaryRoleValue = get(user, 'primaryRole') ? getSelectedFieldFromList(roles, 'name', user.primaryRole) : '';
    const primaryRole = primaryRoleValue ? primaryRoleValue.name : '';

    const userData = {
      firstName: get(user, 'firstName'),
      lastName: get(user, 'lastName'),
      city: get(user, 'city'),
      postcode: get(user, 'postcode'),
      country: get(user, 'country'),
      primaryRole,
      skills: get(user, 'skills', []),
    };
    const updatedLastName = userData.lastName ? `${userData.lastName.charAt(0)}.` : '';
    const skills = userData.skills.reduce((acc, skill) => {
      acc.push(skill.name);
      return acc;
    }, []);
    const metaKeyWord = `${userData.firstName},${userData.lastName},${userData.primaryRole},${skills.join(
      ', ',
    )}, hire, remote, web3, talent, notchup, team.`;
    meta.title = `${userData.firstName} ${updatedLastName} is inviting you to join the fastest growing tech community and get hired by fast growing tech companies | Notchup`;

    meta.metaKeyWord = metaKeyWord;
    meta.image = get(user, 'profilePicture') ? `${get(user, 'profilePicture', '')}?_t=${new Date().getTime()}` : '';
  }
  return meta;
};

export const isEditAllowed = (data, role) => {
  const { talentUserId = '' } = data;
  const userId = StorageService.get('userId');
  if (userExists() && (role === '1' || role === '3') && talentUserId === userId) {
    return true;
  }
  return false;
};

export const showSocialMedia = (data, role) => {
  const { talentUserId = '' } = data;
  const userId = StorageService.get('userId');
  if (userExists() && talentUserId === userId && (role === '1' || role === '4' || role === '2')) {
    return true;
  }
  return false;
};

export const downloadPDFOptions = [
  { label: 'Full Profile', value: 'fullProfile' },
  { label: 'Masked Profile', value: 'maskedProfile' },
];

export const githubRatingsList = [
  { key: 'security', name: 'Security' },
  { key: 'reusability', name: 'Reusability' },
  { key: 'scalability', name: 'Scalability' },
  { key: 'reliability', name: 'Reliability' },
];

export const handleGithubConnect = () => {
  const profileId = StorageService.get('profileId');
  window.open(`https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&state=${profileId}`, '_self');
};
