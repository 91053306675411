/**
 * Signup Page
 *
 * This is the Signup page for the App, at the '/signup' route
 */

import React, { Component, createRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { sha256 } from 'js-sha256';
import { FormGroup } from 'reactstrap';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { reduxForm, change, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { VALIDATION } from 'utils/constants';
import request from 'utils/request';
import StorageService from 'utils/StorageService';
import SVG from 'react-inlinesvg';
import {
  API_URL,
  AUTH,
  REFERRAL,
  AGENCY,
  TALENT,
  EMAIL,
  TERMS_CONDITION_URL,
  circleTickIcon,
  brandingIcon,
  signUpUserAccountType,
  arrowRightIcon,
  CM_CONTACT_SALES_URL,
  errorIcon,
  TALENT_SIGNUP_PAGE_URL,
  PROD_URL,
  companiesIcon,
  clientIcon,
} from 'containers/App/constants';
import injectReducer from 'utils/injectReducer';
import SVGIcon from 'components/SVGIcon';
import injectSaga from 'utils/injectSaga';
import { getUserRoleFromURL } from 'utils/Helper';
import { getBtnClass } from 'containers/Auth/PersonalDetails/utils';
import { loadRepos } from 'containers/App/actions';
import { makeSelectLoading, makeSelectSuccess, makeSelectError } from 'containers/App/selectors';
import { loginLink, recruiterLoginLink, ambassadorLoginLink, handleCloseIframeContainer } from 'containers/App/utils';
import {
  H1,
  P,
  A,
  OnboardingForm,
  OnBoardingFormBody,
  FormLabel,
  Button,
  ToastifyMessage,
  ContainerMod,
  Card,
  ProgressMod,
  H4,
} from 'components';
import { RenderRadioSelection } from 'utils/Fields';
import { white, primaryNew } from 'themes/variables';
import { defaultProps, propTypes } from 'containers/proptypes';
import messages from './messages';
import {
  signUpEmail,
  signUpPassword,
  changePrivacyPolicy,
  signUp,
  signUpReferral,
  signUpAccountType,
  changeUserSelection,
} from './actions';
import {
  makeSelectEmail,
  makeSelectPassword,
  makeSelectPrivacyCheck,
  makeSelectReferralVal,
  makeSelectAccountType,
  makeUserSelected,
} from './selectors';
import reducer, { initialState } from './reducer';
import { SignupFields } from './SignupFields';
import saga from './saga';
import { key } from './constants';
import { ContactModal, UserBulletPointList } from './signup-styles';

const UserType = (radioUserType) => [
  {
    Component: null,
    condition: radioUserType === 'client',
    value: 'client',
    label: 'Employer',
    subLabel: 'Hire talents and teams',
    className: 'custom-radio radio-label d-flex justify-content-center',
    selectedRadioClassName: 'custom-radio-focus',
    image: companiesIcon,
  },
  {
    Component: null,
    condition: radioUserType === 'talent',
    value: 'talent',
    label: 'Talent',
    subLabel: 'Apply for job',
    className: 'custom-radio radio-label d-flex justify-content-center',
    selectedRadioClassName: 'custom-radio-focus',
    image: clientIcon,
  },
];

export class SignUpPage extends Component {
  constructor(props) {
    super(props);
    const talentToken = get(props, 'match.params.talentToken', '');
    const referralToken = get(props, 'location.referralToken', '');
    const teamId = get(props, 'location.teamId', '');
    const role = get(props, 'location.params.role', '') || get(props, 'location.pathname', '').split('/')[1];
    const defaultRole = signUpUserAccountType.find((ele) => ele.value === role);
    const params = get(props, 'location.params', {});
    const referralId = params.userId ? params.userId : '';
    this.state = {
      agencyTalent: false,
      talentToken,
      referralToken,
      disableEmail: false,
      talentActive: true,
      isAgency: false,
      isRecruiter: false,
      isAmbassador: false,
      referral: referralId,
      btnName: 'talent_signup',
      teamId,
      userRole: defaultRole,
      showContactModal: false,
      isIframe: false,
      contentHeight: 0,
    };
    this.contentRef = createRef();
  }

  componentDidMount() {
    const { talentToken, referral, referralToken, userRole } = this.state;
    const { onChangeReferral, onChangeAccountType, dispatch, onChangeUserSelection } = this.props;
    StorageService.set('selectedRoleType', 'talent', { hash: true });
    const selectedRole = window.self !== window.top ? 'client' : userRole;
    dispatch(change(key, 'accountType', selectedRole));
    onChangeUserSelection(false);
    onChangeReferral(referral);
    onChangeAccountType(selectedRole);

    this.getUserType();
    if (talentToken) {
      this.getTalentDetails(talentToken);
      onChangeUserSelection(true);
    }
    if (referralToken) {
      onChangeUserSelection(true);
      this.getReferralDetails(referralToken);
    }
    this.setState({ isIframe: window.self !== window.top });
  }

  componentWillUnmount() {
    const { onChangeUserSelection, onChangeAccountType } = this.props;
    onChangeUserSelection(false);
    onChangeAccountType(null);
  }

  componentDidUpdate() {
    const { isIframe, contentHeight } = this.state;
    if (this.contentRef.current?.scrollHeight && !contentHeight && isIframe) {
      this.setState({ contentHeight: this.contentRef.current.scrollHeight });
      const height = this.contentRef.current.scrollHeight;
      window.parent.postMessage({ height, elementId: 'iframe-signup' }, '*');
    }
  }

  sendHeightToParent = () => {
    if (this.contentRef.current) {
      const height = this.contentRef.current.scrollHeight; // Get the height of the content container
      window.parent.postMessage({ height, elementId: 'iframe-signup' }, '*');
    }
  };

  getUserType = () => {
    const { history } = this.props;
    const selectedRole = history?.location?.hash.split('#')[1];
    const { isRecruiter, isAmbassador } = this.state;
    if (selectedRole === 'company') {
      StorageService.set('selectedRoleType', selectedRole, { hash: true });
      this.setState({ talentActive: false, btnName: 'company_signup' });
    }
    if (selectedRole === 'talent') {
      StorageService.set('selectedRoleType', selectedRole, { hash: true });
      this.setState({ talentActive: true, btnName: 'talent_signup' });
    }
    if (history?.location?.pathname.split('/').includes('agency')) {
      StorageService.set('selectedRoleType', 'agency', { hash: true });
      this.setState({ isAgency: true, btnName: 'agency_signup' });
    }
    if (history?.location?.pathname.split('/').includes('talent-partner')) {
      StorageService.set('selectedRoleType', 'recruiter', { hash: true });
      // eslint-disable-next-line no-unused-expressions
      !isRecruiter && this.setState({ isRecruiter: true, btnName: 'partner_signup' });
    }
    if (history?.location?.pathname.split('/').includes('ambassador')) {
      StorageService.set('selectedRoleType', 'ambassador', { hash: true });
      // eslint-disable-next-line no-unused-expressions
      !isAmbassador && this.setState({ isAmbassador: true, btnName: 'ambassador_signup' });
    }
    if (history?.location?.pathname.split('/').includes('client')) {
      StorageService.set('selectedRoleType', 'client', { hash: true });
      // eslint-disable-next-line no-unused-expressions
      this.setState({ talentActive: false, btnName: 'employer_signup' });
    }
  };

  getTalentDetails = (talentToken) => {
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${AUTH}${AGENCY}${TALENT}${EMAIL}?token=${talentToken}`;
    request(requestURL, data)
      .then(this.setUserDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  getReferralDetails = (referralToken) => {
    const data = { method: 'GET' };
    const requestURL = `${API_URL}${AUTH}${REFERRAL}${EMAIL}?token=${referralToken}`;
    request(requestURL, data)
      .then(this.setReferralDetails)
      .catch(() => {
        toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
      });
  };

  setReferralDetails = (response) => {
    const { dispatch, onChangeEmail } = this.props;
    if (get(response, 'status')) {
      const { data } = response;
      const email = data;
      this.setState({ disableEmail: true });
      dispatch(change(key, 'email', email));
      onChangeEmail(email);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message', '')} type="error" />, { className: 'Toast-error' });
    }
  };

  setUserDetails = (response) => {
    const { dispatch, onChangeEmail } = this.props;
    if (get(response, 'status')) {
      const { data } = response;
      const email = data;
      this.setState({ disableEmail: true, agencyTalent: true });
      dispatch(change(key, 'email', email));
      onChangeEmail(email);
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message', '')} type="error" />, { className: 'Toast-error' });
    }
  };

  textIntro =
    getUserRoleFromURL() === 'talent' ? <FormattedMessage {...messages.textIntro} /> : <FormattedMessage {...messages.textIntroClient} />;

  privacyPolicyButton = (
    <React.Fragment>
      {messages.privacyPolicy1.defaultMessage}
      <A href={TERMS_CONDITION_URL} target="_blank" className="ms-1 me-1 text-decoration-underline">
        <FormattedMessage {...messages.privacyPolicy12} />
      </A>
    </React.Fragment>
  );

  getLoginLink = () => {
    const { isRecruiter, isAmbassador } = this.state;
    let link = loginLink;
    if (isRecruiter) {
      link = recruiterLoginLink;
    }
    if (isAmbassador) {
      link = ambassadorLoginLink;
    }
    return link;
  };

  handleChangeType = (value) => {
    const { onChangeAccountType } = this.props;
    StorageService.set('selectedRoleType', value, { hash: true });
    onChangeAccountType(value);
  };

  handleAccountTypeSelect = (val) => {
    if (val.value === 'talent') {
      this.setState({ talentActive: true, btnName: 'talent_signup' });
    } else {
      this.setState({ talentActive: false, btnName: 'employer_signup' });
    }
    StorageService.set('selectedRoleType', val.value, { hash: true });
    const { onChangeAccountType } = this.props;
    onChangeAccountType(val);
  };

  handleTypeSelection = () => {
    const { accountType, onChangeUserSelection, history } = this.props;
    const { isIframe } = this.state;
    if (accountType === 'client') {
      history.push('/select-company');
    }
    if (accountType === 'talent' && isIframe) {
      this.handleClick(`${PROD_URL}${TALENT_SIGNUP_PAGE_URL}`);
    }

    onChangeUserSelection(true);
  };

  handleClick = (url, extraInfo) => {
    handleCloseIframeContainer({ closeIframe: true, elementId: 'iframe-signup', ...extraInfo });
    if (url) {
      window.open(url, '_blank');
    }
  };

  render() {
    const { handleSubmit, onSubmitForm, loading, responseSuccess, responseError, invalid, accountType, isUserSelected } = this.props;
    const {
      disableEmail,
      agencyTalent,
      talentToken,
      talentActive,
      isAgency,
      isRecruiter,
      isAmbassador,
      btnName,
      teamId,
      isIframe,
      showContactModal,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>{messages.title.defaultMessage}</title>
          <meta name="description" content={messages.metaTitle.defaultMessage} />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ContainerMod className={`${!isAgency ? 'w-auto p-0' : ''}`} data-testid="auth_ContainerMod">
          {isAgency && <ProgressMod value="14" className="onboarding-progress" />}
          <Card className={isAgency ? 'text-center' : 'p-0 bg-transparent'} ref={this.contentRef}>
            {isAgency && (
              <>
                <H1 className="mb-3">Join Us</H1>
                <P className="p-large onboarding-intro">
                  Be a part of world-class community of like minded people carefully vetted by its members and hire them for your projects
                </P>
              </>
            )}
            <form onSubmit={handleSubmit((e) => onSubmitForm(e, agencyTalent, talentToken, teamId))}>
              <OnboardingForm>
                <OnBoardingFormBody className={`${!isAgency ? 'm-auto' : ''} ${isIframe ? 'iframe-content' : ''}`}>
                  <Link to="#" className={`${isIframe ? 'mb-2 mt-2' : 'mb-5'}  d-inline-flex`} title="Notchup">
                    <img src={brandingIcon} alt="Notchup" width={40} height={40} />
                  </Link>

                  {!isAgency && !isRecruiter && !isAmbassador && (
                    <>
                      <H1 className="mb-3">
                        <FormattedMessage {...messages.headingSignup} />
                      </H1>
                      <P className="p16 mb-5" opacityVal="0.5">
                        <FormattedMessage {...messages.createFreeAccount} />
                      </P>
                    </>
                  )}
                  {isRecruiter && (
                    <React.Fragment>
                      <H1 className="mb-3">
                        <FormattedMessage {...messages.recruiterHeading} />
                      </H1>
                      <P className="p16 mb-5" opacityVal="0.5">
                        <FormattedMessage {...messages.createFreeAccount} />
                      </P>
                      {this.recruiterBullets()}
                    </React.Fragment>
                  )}
                  {isAmbassador && (
                    <React.Fragment>
                      <H1 className="mb-3">
                        <FormattedMessage {...messages.ambassadorHeading} />
                      </H1>
                      <P className="p16 mb-5" opacityVal="0.5">
                        <FormattedMessage {...messages.createFreeAccount} />
                      </P>
                      {this.ambassadorBullets()}
                    </React.Fragment>
                  )}
                  {(isRecruiter || isAmbassador || isAgency) && (
                    <SignupFields
                      {...this.props}
                      disableEmail={disableEmail}
                      btnName={btnName}
                      isAgency={isAgency}
                      talentActive={talentActive}
                    />
                  )}
                  {!isRecruiter &&
                    !isAmbassador &&
                    !isAgency &&
                    (!isUserSelected ? (
                      <>
                        <H4 className="mb-3 newH4" opacityVal={'0.5'}>
                          <FormattedMessage {...messages.selectAccountType} />
                        </H4>
                        <FormGroup>
                          <Field
                            name="accountType"
                            component={RenderRadioSelection}
                            data={UserType(accountType)}
                            groupName="accountType"
                            onChangeRadio={this.handleChangeType}
                            selectedRadio={accountType}
                            editFlag={false}
                          />
                        </FormGroup>
                        <Button
                          id="signup_continue_btn"
                          className="btn-primary w-100 mt-4"
                          onClick={() => this.handleTypeSelection()}
                          disabled={!accountType}
                        >
                          <FormattedMessage {...messages.continueBtn} />
                          <SVGIcon className="ms-2" src={arrowRightIcon} iconColor={`rgb(${white})`} />
                        </Button>

                        <P className="p14 mt-4">
                          <FormattedMessage {...messages.textHaveAccount} />
                          {isIframe ? (
                            <Button className="btn btn-link ms-1 me-1 opacity-100" onClick={() => this.handleClick(`${PROD_URL}/login`)}>
                              Log in
                            </Button>
                          ) : (
                            <A href={this.getLoginLink()} className="ms-1 text-decoration-underline">
                              <FormattedMessage {...messages.loginLink} />
                            </A>
                          )}
                        </P>

                        <hr className="mt-4 mb-3" />
                        <P className="p14 mt-4">
                          <span>Want to discuss your global hiring needs?</span>
                          <Button
                            className="btn btn-link inline"
                            onClick={(e) => {
                              e.preventDefault();
                              if (isIframe) {
                                this.handleClick(null, { contactSales: true });
                              } else {
                                this.setState({ showContactModal: true }, () => {
                                  setTimeout(() => {
                                    const s = document.createElement('script');
                                    s.src = `https://webforms.pipedrive.com/f/loader`;
                                    s.async = true;
                                    document.body.appendChild(s);
                                  }, 0);
                                });
                              }
                            }}
                          >
                            Contact sales during sign up
                            <SVGIcon className="ms-1" src={arrowRightIcon} iconColor={`rgb(${primaryNew})`} />
                          </Button>
                        </P>
                      </>
                    ) : (
                      <SignupFields
                        {...this.props}
                        disableEmail={disableEmail}
                        btnName={btnName}
                        isAgency={isAgency}
                        talentActive={talentActive}
                      />
                    ))}
                </OnBoardingFormBody>
                {isAgency && (
                  <>
                    <hr />
                    <OnBoardingFormBody className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                      <FormLabel className="order-2 order-md-1 mt-4 mt-md-0">
                        Have an account?
                        <A href="/agency/login" className="ms-1">
                          Login
                        </A>
                      </FormLabel>
                      <Button
                        name={btnName}
                        className={`${btnName} ${getBtnClass(loading, responseSuccess, responseError)} order-1 order-md-2`}
                        disabled={invalid}
                        id={btnName}
                      >
                        {messages.signupButton.defaultMessage}
                      </Button>
                    </OnBoardingFormBody>
                  </>
                )}
              </OnboardingForm>
            </form>
          </Card>
        </ContainerMod>
        <ContactModal
          isOpen={showContactModal}
          contentLabel="crop"
          className="modal-dialog full-screen-modal"
          style={{ overlay: { zIndex: 12 } }}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          ariaModal
        >
          <div className="modal-content relative">
            <div className="modal-body">
              <div
                className="pipedriveWebForms"
                data-pd-webforms="https://webforms.pipedrive.com/f/6Na9UmyUjBVUADp20qGf9GSr27ZQ9VDzvH5sSKAIbBM4MuqCPt4l4e7tmbQuqGVk8r"
              />
              <Button
                style={{
                  position: 'absolute',
                  top: '20px',
                  right: '50px',
                }}
                className="btn btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showContactModal: false }, () => {});
                }}
              >
                <SVGIcon className="" src={errorIcon} width={20} height={20} />
              </Button>
            </div>
          </div>
        </ContactModal>
      </React.Fragment>
    );
  }

  recruiterBullets() {
    return (
      <>
        <P className="p16 mt-4">
          <FormattedMessage {...messages.recruiterBulletText} />
        </P>
        <UserBulletPointList className="mb-5">
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.recruiterBullets1} />
            </P>
          </li>
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.recruiterBullets2} />
            </P>
          </li>
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.recruiterBullets3} />
            </P>
          </li>
        </UserBulletPointList>
      </>
    );
  }

  ambassadorBullets() {
    return (
      <>
        <P className="p16 mt-4">
          <FormattedMessage {...messages.ambassadorBulletText} />
        </P>
        <UserBulletPointList className="mb-5">
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.ambassadorBullets1} />
            </P>
          </li>
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.ambassadorBullets2} />
            </P>
          </li>
          <li>
            <SVG src={circleTickIcon} />
            <P className="p16" opacityVal="0.5">
              <FormattedMessage {...messages.ambassadorBullets3} />
            </P>
          </li>
        </UserBulletPointList>
      </>
    );
  }
}

SignUpPage.defaultProps = defaultProps;
SignUpPage.propTypes = propTypes;

export function mapDispatchToProps(dispatch) {
  return {
    onChangeEmail: (value) => dispatch(signUpEmail(value)),
    onChangeUserSelection: (value) => dispatch(changeUserSelection(value)),
    onChangeAccountType: (data) => dispatch(signUpAccountType(data)),
    onChangeReferral: (value) => dispatch(signUpReferral(value)),
    onChangePassword: (evt) => {
      const passwordHashed = sha256(evt.target.value);
      dispatch(signUpPassword(passwordHashed));
    },
    onPrivacyPolicyCheck: (evt) => {
      const check = evt.target.checked;
      if (!check) {
        dispatch(changePrivacyPolicy(false));
      } else {
        dispatch(changePrivacyPolicy(true));
      }
    },
    onSubmitForm: (evt, agencyTalent, talentToken, teamId) => {
      if (evt !== undefined && evt.preventDefault) {
        evt.preventDefault();
      }
      dispatch(loadRepos());
      dispatch(signUp(agencyTalent, talentToken, teamId));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  accountType: makeSelectAccountType(),
  email: makeSelectEmail(),
  password: makeSelectPassword(),
  privacyCheck: makeSelectPrivacyCheck(),
  loading: makeSelectLoading(),
  responseSuccess: makeSelectSuccess(),
  responseError: makeSelectError(),
  referral: makeSelectReferralVal(),
  isUserSelected: makeUserSelected(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key, reducer });
const withSaga = injectSaga({ key, saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  reduxForm({
    form: key,
    initialValues: initialState,
    touchOnChange: true,
  }),
)(SignUpPage);
