/*
 * EmptyScreenCard Component
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, P, Button } from 'components';

import { CLIENT, AGENCY } from 'containers/App/constants';
import { PrimarySVG, EmptyCardContainer } from 'containers/ClientHomePage/styles';
import messages from './messages';
import { withRouter } from 'react-router-dom';
import { redirectTo } from 'containers/App/utils';

function EamptyScreen({
  history,
  children,
  cardTitle,
  cardDescription,
  cardClasses,
  iconClasses,
  icon,
  textContainersClasses,
  screenType,
  userType,
  ...props
}) {
  const redirectionPageURL = () => {
    let urlStr = '';
    if (screenType === 'timesheet' || screenType === 'talent') {
      urlStr = `${CLIENT}/talent-listing`;
      if (['3'].includes(userType)) {
        urlStr = `${AGENCY}/talent`;
      }
    }

    // history.push(urlStr);
    redirectTo(null, urlStr, { modalType: 'add' });
  };

  return (
    <Card className={cardClasses} {...props} data-testid="empty-screen-card">
      <div>
        <PrimarySVG src={icon} width="40" height="40" className={iconClasses} />
      </div>
      <EmptyCardContainer className={textContainersClasses}>
        {cardTitle && (
          <P className="p20 mb-2">
            <FormattedMessage {...cardTitle} />
          </P>
        )}
        {cardDescription && (
          <P className="p16" opacityVal="0.5">
            <FormattedMessage {...cardDescription} />
          </P>
        )}
        {children}
        {screenType && (
          <Button className="btn-primary btn-sm mt-4" onClick={() => redirectionPageURL()}>
            {screenType === 'timesheet' && 'Find talents/teams'}
            {screenType === 'talent' && <FormattedMessage {...messages.searchTelents} />}
          </Button>
        )}
      </EmptyCardContainer>
    </Card>
  );
}

EamptyScreen.propTypes = {
  history: PropTypes.object,
  cardTitle: PropTypes.any,
  cardDescription: PropTypes.any,
  icon: PropTypes.any,
  textContainersClasses: PropTypes.string,
  cardClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  screenType: PropTypes.string,
  userType: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(EamptyScreen);
